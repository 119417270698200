import { Component, OnInit, EventEmitter } from '@angular/core'
import { BaseView, Grange } from 'grange'

@Component({
  selector: 'app-export-crxlsx',
  templateUrl: './export-crxlsx.component.html',
  styleUrls: ['./export-crxlsx.component.scss'],
})
export class ExportCRxlsxComponent extends BaseView implements OnInit {
  protected file = '/@exportAllCR'
  excelURL = ''
  excelTitle = ''
  spinner: boolean = false
  emitter = new EventEmitter()

  constructor(public grange: Grange) {
    super(grange)
  }

  ngOnInit() {
    this.contextPath.subscribe((path) => {
      this.excelURL = path + this.file
    })
    this.excelTitle = 'all_CR.xlsx'
    this.context.subscribe((context) => {
      this.excelTitle = 'Detailed report CR - ' + context.title + '.xlsx'
    })
    this.emitter.subscribe((res) => (this.spinner = res))
  }
}
