import { Title } from '@angular/platform-browser'
import { Component, OnInit } from '@angular/core'
import { Grange, BaseViewModel } from 'grange'
import { BaseItem } from '@guillotinaweb/grange-core'
import { ProjectResource } from 'app/models/project.model'
import { FormGroup } from '@angular/forms'
import { PackResource } from 'app/models/pack.model'

@Component({
  selector: 'app-add-order',
  templateUrl: './add-order.component.html',
  styleUrls: ['./add-order.component.scss'],
})
export class AddOrderComponent extends BaseViewModel<ProjectResource> implements OnInit {
  path: string
  public packs: Array<BaseItem> = []
  constructor(public grange: Grange) {
    super(grange)
  }

  ngOnInit(): void {
    this.contextPath.subscribe((path) => (this.path = path))
    this.context.subscribe((context) => {
      if (!context || context['@type'] !== 'abb.orders.project') {
        this.packs = []
      }
      if (context.enable_templates) {
        this.packs = context.enable_templates
          .filter((pack) => pack.review_state === 'internally-published')
          .sort((a, b) => a.title.localeCompare(b.title))
      }
    })
  }

  traverse(route: string) {
    this.grange.traverser.traverse(route)
  }

  public newOrder(form: FormGroup) {
    if (form.invalid) {
      return
    }
    const pack = form.value.pack
    this.grange.core.api
      .post(`${this.path}/@createOrder`, { pack: pack.UID })
      .subscribe((result: { url: string }) => {
        this.grange.traverser.traverse(`${result.url}/@@addOrderStep2`)
      })
  }

  public getLabel = (pack) => {
    let label = pack.title
    if (pack.description) {
      label = `${label} - ${pack.description}`
    }
    return label
  }
}
