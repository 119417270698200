import { Component, OnInit, ChangeDetectorRef, AfterViewInit, ViewChild } from '@angular/core'
import { BaseView, Grange } from 'grange'
import { Client } from '../../models/client.model'
import { markForCheck } from '@guillotinaweb/pastanaga-angular'
import { MatTabGroup } from '@angular/material/tabs'

@Component({
  selector: 'app-manage-client',
  templateUrl: './manage-client.component.html',
  styleUrls: ['./manage-client.component.scss'],
})
export class ManageClientComponent extends BaseView implements OnInit, AfterViewInit {
  public editTitle = false
  public title: string
  protected query_packs
  protected query_projects
  public search_pack: string
  public search_project: string
  client_id: string
  client: Client
  @ViewChild('matTabs') matTabs: MatTabGroup

  constructor(public grange: Grange, private cdr: ChangeDetectorRef) {
    super(grange)
  }

  ngOnInit() {
    this.context.subscribe((res) => {
      const url_elements = res['@id'].split('/')
      this.client = res as unknown as Client
      this.client_id = url_elements[url_elements.length - 1]
      this.updateQueryProjects(false)
      this.updateQueryPacks(false)
    })
  }

  ngAfterViewInit() {
    this.alignInkBar()
  }
  alignInkBar() {
    setTimeout(() => {
      this.matTabs._tabHeader._alignInkBarToSelectedTab()
    }, 1000)
  }

  updateQueryProjects(emit: boolean) {
    const newQuery = {
      portal_type: 'abb.orders.project',
      // fullobject: true,
      metadata_fields: ['modified', 'enable_templates'],
    }
    if (this.search_project) {
      Object.assign(newQuery, { SearchableText: this.search_project })
    }
    this.query_projects = newQuery
    if (emit) {
      markForCheck(this.cdr)
    }
  }

  updateQueryPacks(emit: boolean) {
    const newQuery = {
      portal_type: 'abb.orders.pack',
      metadata_fields: 'modified',
      // fullobject: true,
    }
    if (this.search_pack) {
      Object.assign(newQuery, { SearchableText: this.search_pack })
    }
    this.query_packs = newQuery
    if (emit) {
      markForCheck(this.cdr)
    }
  }

  public save() {
    if (!this.client) {
      return
    }

    this.grange.core.resource.save(this.client['@id'], this.client).subscribe((result) => {
      this.client = result
    })
  }

  public startEdit() {
    this.title = this.client.title
    this.editTitle = true
  }

  public endEdit() {
    this.editTitle = false
  }

  public changeTitle() {
    this.client.title = this.title
    this.endEdit()
    this.save()
  }
}
