export const keyboardKeys = {
    enter: 'Enter',
    space: ' ',
    backspace: 'Backspace',
    esc: 'Escape',
    tab: 'Tab'
};

export const mouseCodes = {
    left: 0,
};
