import { Component } from '@angular/core'
import { BaseComponent } from '../base'
import { TraverserSelectors } from '@guillotinaweb/ngx-state-traverser'
import { Grange } from 'grange'

@Component({
  selector: 'app-breadcumbs',
  templateUrl: './breadcumbs.component.html',
  styleUrls: ['./breadcumbs.component.scss'],
})
export class BreadcumbsComponent extends BaseComponent {
  ancestors = TraverserSelectors.TraverseToAncestors(this.grange.store, '.')

  constructor(public grange: Grange) {
    super(grange)
  }
}
