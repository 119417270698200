import { Component, OnInit, Input } from '@angular/core'
import { BaseComponent } from '../base'
import { Grange } from 'grange'
import { take } from 'rxjs/operators'

interface Actor {
  '@id': string
  id: string
  fullname: string
  username: string
}
interface History {
  action: string
  actor: Actor
  comments: string
  review_state: string
  state_title: string
  time: string
  transition_title: string
  type: 'workflow'
}

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss'],
})
export class HistoryComponent extends BaseComponent implements OnInit {
  @Input() useH4 = false
  public histories: Array<History>
  public timezone = 'GMT+0400'
  public aprovedBy: string
  aprovedByClient: string
  aprovedByABB: string
  aprovedByABBDate: string
  aprovedByClientDate: string

  constructor(public grange: Grange) {
    super(grange)
  }

  ngOnInit(): void {
    this.contextPath.subscribe((path) => {
      this.grange.core.resource
        .get(`${path}/@history`)
        .pipe(take(1))
        .subscribe((histories: Array<History>) => {
          this.histories = histories
          for (const history of this.histories) {
            if (history.action === 'approved-by-client') {
              this.aprovedByClient = history.actor.fullname
              this.aprovedByClientDate = history.time
            }
            if (history.action === 'asend-to-client-approval') {
              this.aprovedByABB = history.actor.fullname
              this.aprovedByABBDate = history.time
            }
          }
        })
    })
  }
}
