import { NgModule } from '@angular/core'
import { SchemaFormModule, WidgetRegistry } from 'ngx-schema-form'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { CommonModule } from '@angular/common'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { HttpClientModule } from '@angular/common/http'
import { TraversalModule, Resolver } from 'angular-traversal'
import { RESTAPIResolver } from './traversal'
import { GrangeFormModule } from '@guillotinaweb/grange-form'
import { en } from './i18n/en'
import { environment } from '../environments/environment'
import { AngularSvgIconModule } from 'angular-svg-icon'
import { StoreDevtoolsModule } from '@ngrx/store-devtools'
import { GrangeRootModule } from 'grange'
import { StoreModule } from '@ngrx/store'

import { AppComponent } from './app.component'
import { ThemeModule } from './theme/theme.module'
import { SharedModule } from './shared/shared.module'
import { LoginComponent } from './views/login/login.component'
import { AddComponent } from './views/add/add.component'
import { EditComponent } from './views/edit/edit.component'
import { FolderComponent } from './views/folder/folder.component'
import { ViewComponent } from './views/view/view.component'
import { LogoutComponent } from './views/logout/logout.component'
import { PreOrderComponent } from './views/pre-order/pre-order.component'
import { OrdersReviewComponent } from './views/orders-review/orders-review.component'
import { AllTemplatesComponent } from './views/all-templates/all-templates.component'
import { AllChangeRequestsComponent } from './views/all-change-requests/all-change-requests.component'
import { ReportLBxlsxFormComponent } from './views/report-lbxlsx-form/report-lbxlsx-form.component'
import { ReportTotalComponent } from './views/report-total/report-total.component'
import { ExportCRxlsxComponent } from './views/export-crxlsx/export-crxlsx.component'
import { UsersComponent } from './views/users/users.component'
import { UserDialogComponent } from './views/users/user-dialog/user-dialog.component'
import { UserFormComponent } from './views/users/user-form/user-form.component'
import { GroupsComponent } from './views/groups/groups.component'
import { GroupsDialogComponent } from './views/groups/groups-dialog/groups-dialog.component'
import { GroupFormComponent } from './views/groups/group-form/group-form.component'
import { ClientsComponent } from './views/clients/clients.component'
import { WelcomeComponent } from './views/welcome/welcome.component'

import { ItemComponent } from './components/item/item.component'
import { SiteComponent } from './components/site/site.component'
import { SearchComponent } from './components/search/search.component'
import { ActionsComponent } from './components/actions/actions.component'
import { WorkflowComponent } from './components/workflow/workflow.component'
import { ItemIconPipe } from './components/item/item-icon.pipe'
import { ItemImagePipe } from './components/item/item-image.pipe'
import { ProjectSelectorComponent } from './components/project-selector/project-selector.component'
import { BreadcumbsComponent } from './components/breadcumbs/breadcumbs.component'
import { AdminComponent } from './components/admin/admin.component'
import { PagesComponent } from './components/pages/pages.component'

import { PackComponent } from './forms/pack/pack.component'
import { MainGroupComponent } from './forms/main-group/main-group.component'
import { DialogFormComponent } from './forms/dialog-form/dialog-form.component'
import { OptionComponent } from './forms/option/option.component'
import { GroupOptionComponent } from './forms/group-option/group-option.component'
import { ChangeRequestComponent } from './forms/change-request/change-request.component'
import { ProjectComponent } from './forms/project/project.component'
import { OrderComponent } from './forms/order/order.component'
import { ClientComponent } from './forms/client/client.component'

import { StringWidgetComponent } from './widgets/string-widget/string-widget.component'
import { WidgetRegestryService } from './widgets/widget-regestry.service'
import { ObjectWidgetComponent } from './widgets/object-widget/object-widget.component'
import { TextAreaWidgetComponent } from './widgets/text-area-widget/text-area-widget.component'
import { CheckboxWidgetComponent } from './widgets/checkbox-widget/checkbox-widget.component'
import { ButtonWidgetComponent } from './widgets/button-widget/button-widget.component'
import { SelectWidgetComponent } from './widgets/select-widget/select-widget.component'
import { ArrayWidgetComponent } from './widgets/array-widget/array-widget.component'
import { WelcomeAdminComponent } from './views/welcome-admin/welcome-admin.component'
import { ManageClientComponent } from './views/manage-client/manage-client.component'
import { ManageProjectComponent } from './views/manage-project/manage-project.component'
import { AddAdminComponent } from './views/add-admin/add-admin.component'
import { ManagePackComponent } from './views/manage-pack/manage-pack.component'
import { WelcomeProjectComponent } from './views/welcome-project/welcome-project.component'
import { ViewOrderComponent } from './views/view-order/view-order.component'
import { ImageSelectorComponent } from './components/image-selector/image-selector.component'
import { EditAdminComponent } from './views/edit-admin/edit-admin.component'
import { SharingComponent } from './components/sharing/sharing.component'
import { GroupsDialogUsersComponent } from './views/groups/groups-dialog-users/groups-dialog-users.component'
import { GroupUsersComponent } from './views/groups/group-users/group-users.component'
import { AddOrderComponent } from './views/add-order/add-order.component'
import { AddChangeRequestComponent } from './views/add-change-request/add-change-request.component'
import { HistoryComponent } from './components/history/history.component'
import { GrangeSrcPipe } from './pipes/grange-src.pipe'
import { DownloadFileDirective } from './directives/download-file.directive'
import { ViewChangeRequestComponent } from './views/view-change-request/view-change-request.component'
import { ViewPackComponent } from './views/view-pack/view-pack.component'
import { EditOrderComponent } from './views/edit-order/edit-order.component'
import { PricePipe } from './pipes/price.pipe'
import { StatePipe } from './pipes/state.pipe'
import { QuillModule } from 'ngx-quill'
import { GroupsDialogSharingComponent } from './views/groups/groups-dialog-sharing/groups-dialog-sharing.component'
import { GroupSharingComponent } from './views/groups/group-sharing/group-sharing.component'
import { FileSelectorComponent } from './components/file-selector/file-selector.component'
import { EmptyInputComponent } from './components/empty-input/empty-input.component'
import { ResetComponent } from './views/reset/reset.component'
import { RealResetComponent } from './views/real-reset/real-reset.component'
import { MAT_DATE_LOCALE } from '@angular/material/core'
import { ManageProjectAccessComponent } from './views/manage-project/manage-project-access/manage-project-access.component'
import { ManageProjectAccessDialogComponent } from './views/manage-project/manage-project-access/manage-project-access-dialog/manage-project-access-dialog.component'
import { GroupDefaultRolesPipe } from './pipes/group-default-roles.pipe'
import { ClientsAddDialogComponent } from './views/clients/clients-add-dialog/clients-add-dialog.component'
import { ManageProjectXlistsComponent } from './views/manage-project/manage-project-xlists/manage-project-xlists.component'
import { ManageProjectXlistsDialogComponent } from './views/manage-project/manage-project-xlists/manage-project-xlists-dialog/manage-project-xlists-dialog.component'
import { PadPipe } from './pipes/pad.pipe'
import { ManageEmailsComponent } from './views/manage-emails/manage-emails.component'
import { TemplateProjectComponent } from './views/view-pack-project/view-pack-project.component'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { ManageProjectDeleteXlistDialogComponent } from './views/manage-project/manage-project-xlists/manage-project-delete-xlist-dialog/manage-project-delete-xlist-dialog.component'
import { ProjectsInXlistsExpandedItemComponent } from './views/manage-client/projects-in-xlists-expanded-item/projects-in-xlists-expanded-item.component'
import { ManageProjectAssignXlistDialogComponent } from './views/manage-project/manage-project-xlists/manage-project-assign-xlist-dialog/manage-project-assign-xlist-dialog.component'

const COMPONENTS = [
  PagesComponent,
  SiteComponent,
  AdminComponent,
  ItemComponent,
  SearchComponent,
  ActionsComponent,
  WorkflowComponent,
  ItemIconPipe,
  ItemImagePipe,
  ClientComponent,
  PackComponent,
  ProjectSelectorComponent,
  UserFormComponent,
  GroupFormComponent,
  BreadcumbsComponent,
  MainGroupComponent,
  OptionComponent,
  GroupOptionComponent,
  ChangeRequestComponent,
  ProjectComponent,
  OrderComponent,
  EmptyInputComponent,
]

const VIEWS = [
  ViewComponent,
  LoginComponent,
  AddComponent,
  EditComponent,
  FolderComponent,
  LogoutComponent,
  PreOrderComponent,
  OrdersReviewComponent,
  AllTemplatesComponent,
  AllChangeRequestsComponent,
  ReportLBxlsxFormComponent,
  ReportTotalComponent,
  ExportCRxlsxComponent,
  UsersComponent,
  UserDialogComponent,
  GroupsComponent,
  GroupsDialogComponent,
  ClientsComponent,
  StringWidgetComponent,
  ObjectWidgetComponent,
  TextAreaWidgetComponent,
  CheckboxWidgetComponent,
  ButtonWidgetComponent,
  SelectWidgetComponent,
  ArrayWidgetComponent,
  DialogFormComponent,
  WelcomeComponent,
  WelcomeAdminComponent,
  ManageClientComponent,
  ManageProjectComponent,
  AddAdminComponent,
  ManagePackComponent,
  WelcomeProjectComponent,
  ViewOrderComponent,
  EditAdminComponent,
  AddOrderComponent,
  AddChangeRequestComponent,
  ViewChangeRequestComponent,
  ViewPackComponent,
  EditOrderComponent,
  ResetComponent,
  RealResetComponent,
  TemplateProjectComponent,
  ManageEmailsComponent,
]

@NgModule({
  declarations: [
    ...VIEWS,
    ...COMPONENTS,
    AppComponent,
    ImageSelectorComponent,
    SharingComponent,
    GroupsDialogUsersComponent,
    GroupUsersComponent,
    HistoryComponent,
    GrangeSrcPipe,
    DownloadFileDirective,
    PricePipe,
    StatePipe,
    GroupsDialogSharingComponent,
    GroupSharingComponent,
    FileSelectorComponent,
    ManageProjectAccessComponent,
    ManageProjectAccessDialogComponent,
    GroupDefaultRolesPipe,
    ClientsAddDialogComponent,
    ManageProjectXlistsComponent,
    ManageProjectXlistsDialogComponent,
    PadPipe,
    ManageProjectDeleteXlistDialogComponent,
    ProjectsInXlistsExpandedItemComponent,
    ManageProjectAssignXlistDialogComponent,
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ThemeModule.forRoot(),
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    TraversalModule,
    GrangeRootModule.forRoot(),
    StoreModule.forRoot({}),
    StoreDevtoolsModule.instrument({
      logOnly: environment.production,
      maxAge: 25,
    }),
    AngularSvgIconModule.forRoot(),
    SchemaFormModule.forRoot(),
    GrangeFormModule,
    QuillModule.forRoot(),
  ],
  providers: [
    {
      provide: 'CONFIGURATION',
      useValue: {
        CLIENT_TIMEOUT: 900000,
        BACKEND_URL: 'https://xlists-back.abb.iskra.cat',
        // BACKEND_URL: 'http://localhost:8080/xlists',
      },
    },
    { provide: 'LANG', useValue: 'en_US' },
    {
      provide: 'TRANSLATIONS',
      useValue: {
        en_US: { ...en } as any,
      },
    },
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    { provide: Resolver, useClass: RESTAPIResolver },
    { provide: WidgetRegistry, useClass: WidgetRegestryService },
  ],
  entryComponents: [...VIEWS],
  bootstrap: [AppComponent],
})
export class AppModule {}
