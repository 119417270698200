import { Component, OnInit, ChangeDetectorRef, OnDestroy, AfterViewInit } from '@angular/core'
import { BaseView, Grange } from 'grange'
import { User } from '../users/interfaces'
import { AppSettings } from 'app/theme/app.settings'

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss'],
})
export class WelcomeComponent extends BaseView implements OnInit, OnDestroy, AfterViewInit {
  public isAdmin = false
  public user: User
  constructor(
    public grange: Grange,
    private appSettings: AppSettings,
    private cdr: ChangeDetectorRef
  ) {
    super(grange)
  }

  ngOnInit() {
    this.grange.core.auth.getUser().subscribe((user) => {
      this.user = user
      this.isAdmin = !!this.user && !!this.user.roles && this.user.roles.includes('Manager')
    })
    this.appSettings.settings.fixedSidenav = false
    this.appSettings.settings.sidenavIsOpened = false
    this.appSettings.settings.menu = 'horizontal'
  }
  ngAfterViewInit() {
    this.cdr.markForCheck()
  }

  ngOnDestroy() {
    this.appSettings.settings.fixedSidenav = true
    this.appSettings.settings.sidenavIsOpened = true
    this.appSettings.settings.menu = 'vertical'
  }
}
