import { Component, OnInit } from '@angular/core'
import { Grange } from 'grange'
import { Router } from '@angular/router'

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss'],
})
export class LogoutComponent implements OnInit {
  constructor(public grange: Grange) {}

  ngOnInit() {}

  onCancel() {
    window.history.back()
    // this.grange.traverser.traverse('.');
  }

  onLogout() {
    this.grange.core.auth.logout()
    this.grange.traverser.traverse('/@@login')
  }
}
