import { Menu } from '../../theme/components/menu/menu.model'

export const adminMenu: Array<Menu> = [
  new Menu(1, 'GROUPS', '/@@groups', null, 'group', null, false, 0, false),
  new Menu(
    2,
    'MANAGE X-LISTS & PROJECTS',
    '/@@clients',
    null,
    'business_center',
    null,
    false,
    0,
    false
  ),
  new Menu(3, 'USERS', '/@@users', null, 'person', null, false, 0, false),
  new Menu(4, 'MAIL', '/@@mails', null, 'alternate_email', null, false, 0, false),
  new Menu(5, 'STRATEGIC REPORT', '/@@reportTotal', null, 'visibility', null, false, 0, false),
]

export const adminMenuHierarchy = [
  { parentMenuItem: 2, type: 'abb.orders.pack', view: '*' },
  { parentMenuItem: 2, type: 'abb.orders.client', view: '*' },
  { parentMenuItem: 2, type: 'abb.orders.project', view: '*' },
]
