import { Component, SimpleChanges, OnChanges, OnInit, Input } from '@angular/core'
import { BaseFormsComponent } from '../base.forms.component'
import { Grange } from 'grange'
import { Pack } from '../../models/pack.model'
import { BaseItem } from '@guillotinaweb/grange-core'
import { NestedTreeControl } from '@angular/cdk/tree'
import { MatDialog } from '@angular/material/dialog'
import { MatTreeNestedDataSource } from '@angular/material/tree'
import { Observable, zip, Subscriber, concat, of, forkJoin } from 'rxjs'
import {
  DeleteDialogData,
  DeleteDialogComponent,
} from 'app/shared/dialogs/delete-dialog/delete-dialog.component'
import { DialogFormComponent, FormDialogData } from '../dialog-form/dialog-form.component'
import { PAGE_SIZE_OVERRIDED } from 'app/core'

interface TreeItem extends BaseItem {
  children?: Array<TreeItem>
  items?: Array<BaseItem>
  logic?: string
  ref?: string
}

@Component({
  selector: 'app-pack',
  templateUrl: './pack.component.html',
  styleUrls: ['./pack.component.scss'],
})
export class PackComponent extends BaseFormsComponent<Pack> implements OnChanges, OnInit {
  @Input() extraInfo: boolean
  @Input() side: string
  public treeControl = new NestedTreeControl<TreeItem>((node) => node.children)
  public dataSource = new MatTreeNestedDataSource<TreeItem>()
  private originalFullItem: TreeItem

  public get attachment(): string {
    return this.model.file ? this.model.file.filename : ''
  }

  constructor(public grange: Grange, public dialog: MatDialog) {
    super(grange)
  }

  ngOnInit() {
    this.originalFullItem = Object.assign({}, this.model)
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.model) {
      this.getFullItems(this.model).subscribe((childrens: Array<TreeItem>) => {
        this.dataSource.data = childrens
        this.originalFullItem = Object.assign(this.model, { children: childrens })
      })
    }
  }

  private _getFullItem(item: BaseItem): Observable<TreeItem> {
    return new Observable<TreeItem>((sub: Subscriber<TreeItem>) => {
      if (this.side !== undefined) {
        // We are on a project
        this.grange.core.resource
          .get(`${this.side}/@getOptions?option=${item['@id']}&b_size=${PAGE_SIZE_OVERRIDED}`)
          .subscribe((itemLoaded: TreeItem) => {
            if (itemLoaded.items && itemLoaded.items.length) {
              this.getFullItems(itemLoaded).subscribe((childrens: Array<TreeItem>) => {
                itemLoaded.children = childrens
                sub.next(itemLoaded)
                sub.complete()
              })
            } else {
              sub.next(itemLoaded)
              sub.complete()
            }
          })
      } else {
        this.grange.core.resource
          .get(`${item['@id']}?b_size=${PAGE_SIZE_OVERRIDED}`)
          .subscribe((itemLoaded: TreeItem) => {
            if (itemLoaded.items && itemLoaded.items.length) {
              this.getFullItems(itemLoaded).subscribe((childrens: Array<TreeItem>) => {
                itemLoaded.children = childrens
                sub.next(itemLoaded)
                sub.complete()
              })
            } else {
              sub.next(itemLoaded)
              sub.complete()
            }
          })
      }
    })
  }

  private getFullItems(model: TreeItem): Observable<Array<TreeItem>> {
    if (model && !model.items) {
      return new Observable((ob) => {
        ob.next([])
        ob.complete()
      })
    }
    if (model) {
      return zip(...model.items.map((item) => this._getFullItem(item)))
    } else {
      return of([])
    }
  }

  hasChild = (_: number, node: TreeItem) => !!node.children && node.children.length > 0

  private updateLogicOptions(item: TreeItem) {
    /// Si les referencies tenen espais o el titol del grup guions, no funcionarà bé.
    // Per cada actualització o borrat d'una opció o grup hem de revsar tots els logics del x-list
    if (item.logic && item.logic !== '') {
      const refLogics = item.logic.split(',')
      const queryNoOptions = {
        portal_type: 'abb.orders.option',
        path: this.path,
        ref: { query: [] },
        b_size: 100,
        metadata_fields: 'ref',
      }
      const queryNeedOptions = {
        portal_type: 'abb.orders.option',
        path: this.path,
        ref: { query: [] },
        b_size: 100,
        metadata_fields: 'ref',
      }
      const queryGroupOptions = {
        portal_type: 'abb.orders.maingroup',
        path: this.path,
        title: { query: [] },
        b_size: 100,
      }
      refLogics.forEach((ref) => {
        if (ref.startsWith('needs')) {
          queryNeedOptions.ref.query.push(ref.split(' ')[2])
        } else if (ref.startsWith('no')) {
          queryNoOptions.ref.query.push(ref.split(' ')[1])
        } else if (ref.startsWith('price')) {
          queryGroupOptions.title.query.push(ref.split(' - ')[1])
        }
      })

      forkJoin({
        no: this.grange.core.resource.find(queryNoOptions, '/', {}),
        needs: this.grange.core.resource.find(queryNeedOptions, '/', {}),
        group: this.grange.core.resource.find(queryGroupOptions, '/', {}),
      }).subscribe((result) => {
        let newLogic = item.logic.split(',')
        newLogic = newLogic.filter((ref) => {
          if (ref.startsWith('needs')) {
            return result['needs'].items.find((item: TreeItem) => item.ref === ref.split(' ')[2])
          } else if (ref.startsWith('no')) {
            return result['no'].items.find((item: TreeItem) => item.ref === ref.split(' ')[1])
          } else if (ref.startsWith('price')) {
            return result['group'].items.find(
              (item: TreeItem) => item.title === ref.split(' - ')[1]
            )
          }
          return false
        })
        if (item.logic !== newLogic.join(',')) {
          item['logic'] = newLogic.join(',')
          this.grange.core.resource
            .save(item['@id'], item)
            .subscribe((data) => console.log('data updated', data))
        }
      })
    }

    if (item.children) {
      item.children.forEach((child) => this.updateLogicOptions(child))
    }
  }

  private _deleteTreeItem(deleteItem: TreeItem, item: TreeItem) {
    console.log('delete item', deleteItem, item)
    if (item.children) {
      item.children = item.children.filter((child: TreeItem) => {
        return child !== deleteItem
      })
      item.children.map((child) => this._deleteTreeItem(deleteItem, child))
    }
  }

  public deleteTreeItem(deleteItem: TreeItem) {
    const data: DeleteDialogData = {
      title: deleteItem.title,
    }
    const dialog = this.dialog.open(DeleteDialogComponent, { data })
    dialog.afterClosed().subscribe((del: boolean) => {
      if (!del) {
        return
      }
      this.grange.core.resource.delete(deleteItem['@id']).subscribe(() => {
        this._deleteTreeItem(deleteItem, this.originalFullItem)
        this.dataSource.data = []
        this.dataSource.data = this.originalFullItem.children
        this.updateLogicOptions(this.originalFullItem)
      })
    })
  }

  public addOnModel(parent: TreeItem, type: string) {
    const data: FormDialogData = {
      mode: 'add',
      model: { '@type': type, parent },
      path: parent['@id'],
    }
    const dialog = this.dialog.open(DialogFormComponent, { data, minWidth: 500 })
    dialog.afterClosed().subscribe((result: { save: boolean; model: any }) => {
      if (!result || !result.save) {
        return
      }
      if (!parent.children) {
        parent.children = []
      }
      parent.children.push(result.model)
      this.dataSource.data = []
      this.dataSource.data = [...this.originalFullItem.children]
    })
  }

  public editModel(model: TreeItem) {
    const virtualModel = Object.assign({}, model)
    // delete virtualModel['@id'];
    const data: FormDialogData = {
      mode: 'edit',
      model: virtualModel,
    }
    const dialog = this.dialog.open(DialogFormComponent, { data, minWidth: 500 })
    dialog.afterClosed().subscribe((result: { save: boolean; model: any }) => {
      if (!result || !result.save) {
        return
      }
      model = Object.assign(model, result.model)
      this.updateLogicOptions(this.originalFullItem)

      // this.dataSource.data = [];
      // this.dataSource.data = [...this.originalFullItem.children];
    })
  }

  public addGroup() {
    this.addOnModel(this.originalFullItem, 'abb.orders.maingroup')
  }

  public setFile($event) {
    if ($event.target.files && $event.target.files.length) {
      let newAttachment: File
      newAttachment = $event.target.files[0]
      const reader = new FileReader()
      reader.readAsDataURL(newAttachment)
      reader.onload = () => {
        if (typeof reader.result === 'string') {
          this.model.file = {
            'content-type': newAttachment.type,
            filename: newAttachment.name,
            data: reader.result.split(',')[1],
            encoding: reader.result.split(';')[1].split(',')[0],
          }
        }
      }
    } else {
      this.model.file = undefined
    }
  }

  // private getDeletedIds(treeItem: TreeItem, deletedIds: Array<string> = []): Array<string> {
  //   if (treeItem.items) {
  //     treeItem.items.map((item: BaseItem) => {
  //       if (item['@id']) {
  //         const find: TreeItem = treeItem.children.find((child: TreeItem) => {
  //           return child['@id'] && child['@id'] === item['@id'];
  //         });
  //         if (!find) {
  //           deletedIds.push(item['@id']) ;
  //         }
  //       }
  //     });
  //   }
  //   if (treeItem.children) {
  //     treeItem.children.map((child) => {
  //       this.getDeletedIds(child, deletedIds);
  //     });
  //   }
  //   return deletedIds;
  // }

  // private saveAll(model: TreeItem, path: string ) {
  //   return new Observable<boolean>((observer) => {

  //     const save: Observable<any> = !!model['@id'] ?
  //     this.grange.core.resource.save(model['@id'], model) :
  //     this.grange.core.resource.create(path, model);
  //     save.subscribe((result) => {
  //       model = Object.assign(model, result);
  //       if (model.children) {
  //         concat(
  //           ...model.children.map((child) => {
  //             return this.saveAll(child, model['@id']);
  //           })
  //           ).subscribe((all) => {
  //             console.log(all);
  //           }, (error) => {
  //             observer.error(error);
  //             observer.complete();
  //           }, () => {
  //               observer.next(true);
  //               observer.complete();
  //           });
  //       } else {
  //         observer.next(true);
  //         observer.complete();
  //       }
  //     }, (error) => {
  //       observer.error(error);
  //       observer.complete();
  //     });
  //   });
  // }

  // public onSave() {
  //   if (this.mode === 'view') {
  //     return;
  //   }
  //   if (!this.form || this.form.invalid) {
  //     return;
  //   }
  //   concat(
  //     this.saveAll(this.originalFullItem, this.path),
  //     ...this.getDeletedIds(this.originalFullItem).map((deleteId: string) => {
  //       return this.grange.core.resource.delete(deleteId);
  //     })
  //   ).subscribe((result) => {
  //     console.log(result);
  //   }, (error) => {
  //     if (error && error.message) {
  //       this.error = error.message;
  //     }
  //   }, () => {
  //     this.afterSave.emit(this.originalFullItem);
  //     console.log('complete');
  //   });
  // }
}
