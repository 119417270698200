import { Component, OnInit, AfterViewInit, HostListener, OnDestroy } from '@angular/core'
import { ChangeRequest } from './change-request'
import { BaseFormsComponent } from '../base.forms.component'
import { Grange } from 'grange'
import { Observable, Subject } from 'rxjs'
import { BaseItem, SearchResults, SearchOptions } from '@guillotinaweb/grange-core'
import {
  DeleteDialogData,
  DeleteDialogComponent,
} from 'app/shared/dialogs/delete-dialog/delete-dialog.component'
import { MatDialog } from '@angular/material/dialog'
import { Normalizer } from 'angular-traversal'
import { takeUntil } from 'rxjs/operators'

@Component({
  selector: 'app-change-request',
  templateUrl: './change-request.component.html',
  styleUrls: ['./change-request.component.scss'],
})
export class ChangeRequestComponent
  extends BaseFormsComponent<ChangeRequest>
  implements OnInit, AfterViewInit, OnDestroy
{
  public get isSubmitMode(): boolean {
    return ['linebuilder-draft', '1-1-submitted-to-the-client', 'submitted-to-abb'].includes(
      this.model.review_state
    )
  }
  public get isApproved(): boolean {
    return [
      'submitted-to-abb',
      'waiting-to-be-approved-by-client',
      'approved',
      'in-execution',
      'finalized',
    ].includes(this.model.review_state)
  }
  public get isExecuting(): boolean {
    return ['in-execution', 'finalized'].includes(this.model.review_state)
  }
  public get showABBResponse(): boolean {
    if (this.isABB) {
      return [
        'submitted-to-abb',
        'waiting-to-be-approved-by-client',
        'approved',
        'in-execution',
        'finalized',
      ].includes(this.model.review_state)
    }
    // if (this.isClient) {
    //   return [ 'waiting-to-be-approved-by-client', 'approved', 'in-execution', 'finalized'].includes(this.model.review_state);
    // }
    return ['waiting-to-be-approved-by-client', 'approved', 'in-execution', 'finalized'].includes(
      this.model.review_state
    )

    // return false;
  }
  public get showABBResponseValidUntil(): boolean {
    if (this.isABB) {
      return [
        'submitted-to-abb',
        'waiting-to-be-approved-by-client',
        'approved',
        'in-execution',
        'finalized',
      ].includes(this.model.review_state)
    }
    return ['waiting-to-be-approved-by-client', 'approved', 'in-execution', 'finalized'].includes(
      this.model.review_state
    )
  }
  public orders: Array<BaseItem> = []
  // public selectedOrders: Array<BaseItem> = [];
  public canViewApproveButton: boolean
  public canViewCancelButton: boolean
  public canViewPrices: boolean
  public isABB: boolean
  public isClient: boolean
  force: boolean = false

  // public get attachment(): string {
  //   return this.model.file ? this.model.file.filename : '';
  // }

  public get text(): string {
    if (!this.model || !this.model.text) {
      return ''
    }
    return this.model.text.data
  }
  public set text(val: string) {
    if (!this.model) {
      return
    }
    if (!this.model.text) {
      this.model.text = {
        'content-type': 'text/html',
        data: '',
        encoding: 'utf8',
      }
    }
    this.model.text.data = val
  }
  public get abb_text(): string {
    if (!this.model || !this.model.abb_text) {
      return ''
    }
    return this.model.abb_text.data
  }
  public set abb_text(val: string) {
    if (!this.model) {
      return
    }
    if (!this.model.abb_text) {
      this.model.abb_text = {
        'content-type': 'text/html',
        data: '',
        encoding: 'utf8',
      }
    }
    this.model.abb_text.data = val
  }
  public listRealatedOrders = []
  public addOrderSearch = ''
  public get filtredOrders(): Array<BaseItem> {
    if (!this.addOrderSearch) {
      return this.orders.slice()
    }
    if (typeof this.addOrderSearch !== 'string') {
      return []
    }
    return this.orders.filter((order) =>
      order.title.toLowerCase().includes(this.addOrderSearch.toLowerCase())
    )
  }

  terminator: Subject<void> = new Subject()

  constructor(public grange: Grange, public dialog: MatDialog, private normalizer: Normalizer) {
    super(grange)
  }

  @HostListener('window:beforeunload', ['$event'])
  handleBeforeUnload($event: any) {
    if (this.hasUnsavedData()) {
      $event.returnValue = true
    }
  }

  hasUnsavedData() {
    if (this.mode === 'edit') {
      return (this.form.dirty || this.form.invalid) && !this.force
    }
  }

  ngOnInit() {
    this.laodCR()
    this.loadOrders()
    this.grange.traverser.beforeTraverse
      .pipe(takeUntil(this.terminator))
      .subscribe(([canTraverse, path]) => {
        if (this.hasUnsavedData()) {
          alert('Before leaving the CR either Cancel or Save it')
          canTraverse.next(false)
        } else {
          canTraverse.next(true)
        }
      })
  }

  ngOnDestroy() {
    this.terminator.next()
  }

  ngAfterViewInit() {
    // rewrites ChangeRequestComponent.ngAfterViewInit() and move this
  }

  private laodCR() {
    this.grange.core.resource.get(this.model['@id'] + '/@viewCR').subscribe((result) => {
      this.listRealatedOrders = result.orders
      this.isABB = result.isABB
      this.isClient = result.isClient
      this.canViewPrices = result.canViewPrices
      this.canViewCancelButton = result.canViewCancelButton
      this.canViewApproveButton = result.canViewApproveButton
      setTimeout(() => {
        this.initForm()
      })
    })
  }

  displayFn(item: BaseItem): string {
    return item && item.title ? item.title : ''
  }

  private loadOrders() {
    this.orders = []
    let parentPath: string
    if (this.path) {
      parentPath = this.path
    }
    if (this.model['@id']) {
      parentPath = this.normalizer.normalize(this.model['@id']).split('/').slice(0, 3).join('/')
    }
    const query = {
      portal_type: 'abb.orders.order',
      path: {
        query: parentPath,
        depth: 3,
      },
      // fullobjects: true,
    }
    const options: SearchOptions = {
      metadata_fields: ['UID'],
    }
    this.grange.core.resource.find(query, parentPath, options).subscribe((result) => {
      this.recursiveLoadOrders(result)
    })
  }

  private recursiveLoadOrders(result: SearchResults) {
    this.orders = [...this.orders, ...result.items]
    if (result.batching && result.batching.next) {
      this.grange.core.api.get(result.batching.next).subscribe((next: SearchResults) => {
        this.recursiveLoadOrders(next)
      })
    }
  }

  // public setFile($event) {
  //   if ($event.target.files && $event.target.files.length) {
  //     let newAttachment: File;
  //     newAttachment = $event.target.files[0];
  //     const reader = new FileReader();
  //     reader.readAsDataURL(newAttachment);
  //     reader.onload = () => {
  //       if (typeof reader.result === 'string') {
  //         this.model.file = {
  //           'content-type': newAttachment.type,
  //           filename: newAttachment.name,
  //           data: reader.result.split(',')[1],
  //           encoding: reader.result.split(';')[1].split(',')[0],
  //         };
  //       }
  //     };
  //   } else {
  //     this.model.file = undefined;
  //   }
  // }

  public onCancel() {
    if (this.mode === 'edit' && this.model.description === 'temp') {
      this.force = true
      this.grange.core.resource.delete(this.model['@id']).subscribe((result) => {
        this.grange.traverser.traverse('../')
      })
    }
    if (this.mode === 'edit') {
      this.force = true
      super.onCancel()
    } else {
      super.onCancel()
    }
  }

  public onSave() {
    if (this.mode === 'view') {
      return
    }
    if (!this.form || this.form.invalid) {
      return
    }
    if (!this.path && !this.model['@id']) {
      // virtual form can't save
      this.afterSave.emit()
      return
    }
    const data: any = Object.assign({}, this.model)
    this.force = true
    data.title = data.title.replace('-temp', '')
    data.description = ''

    if (data.date) {
      const date = new Date(data.date)
      data.date = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
    }
    if (data.delivery_date_to_abb) {
      const delivery_date_to_abb = new Date(data.delivery_date_to_abb)
      data.delivery_date_to_abb = `${delivery_date_to_abb.getFullYear()}-${
        delivery_date_to_abb.getMonth() + 1
      }-${delivery_date_to_abb.getDate()}`
    }
    if (data.delivery_date_to_customer) {
      const delivery_date_to_customer = new Date(data.delivery_date_to_customer)
      data.delivery_date_to_customer = `${delivery_date_to_customer.getFullYear()}-${
        delivery_date_to_customer.getMonth() + 1
      }-${delivery_date_to_customer.getDate()}`
    }
    if (data.implementation_date) {
      const implementation_date = new Date(data.implementation_date)
      data.implementation_date = `${implementation_date.getFullYear()}-${
        implementation_date.getMonth() + 1
      }-${implementation_date.getDate()}`
    }
    this.grange.core.resource.save(data['@id'], data).subscribe(
      (result) => {
        this.afterSave.emit(result)
      },
      (error) => {
        if (error && error.message) {
          this.error = error.message
        }
      }
    )
  }

  public delete() {
    const data: DeleteDialogData = {
      title: this.model.title,
    }
    const dialog = this.dialog.open(DeleteDialogComponent, { data })
    dialog.afterClosed().subscribe((del: boolean) => {
      if (!del) {
        return
      }
      this.grange.traverser.traverse('..')
    })
  }

  public approve() {
    this.grange.core.api.post(`${this.model['@id']}/@CRAproval`, {}).subscribe((result) => {
      console.log(result)
      this.grange.traverser.traverse('..')
    })
  }
}
