import { Component, OnInit } from '@angular/core'
import { Project } from '../../models/project.model'
import { BaseFormsComponent } from '../base.forms.component'
import { BaseItem } from '@guillotinaweb/grange-core'
import { Grange } from 'grange'

@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.scss'],
})
export class ProjectComponent extends BaseFormsComponent<Project> implements OnInit {
  public allTemplates: Array<BaseItem> = []

  constructor(public grange: Grange) {
    super(grange)
  }
  ngOnInit() {}
}
