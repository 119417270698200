import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-checkbox-widget',
  templateUrl: './checkbox-widget.component.html',
  styleUrls: ['./checkbox-widget.component.scss'],
})
export class CheckboxWidgetComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
