import { NgModule, ModuleWithProviders } from '@angular/core'
import { ApplicationsComponent } from './components/applications/applications.component'
import { CommonModule } from '@angular/common'
import { AppSettings } from './app.settings'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { TopInfoContentComponent } from './components/top-info-content/top-info-content.component'
import { MatButtonModule } from '@angular/material/button'
import { MatButtonToggleModule } from '@angular/material/button-toggle'
import { MatTreeModule } from '@angular/material/tree'
import { MatStepperModule } from '@angular/material/stepper'
import { MatTooltipModule } from '@angular/material/tooltip'
import { MatToolbarModule } from '@angular/material/toolbar'
import { MatTabsModule } from '@angular/material/tabs'
import { MatTableModule } from '@angular/material/table'
import { MatAutocompleteModule } from '@angular/material/autocomplete'
import { MatCardModule } from '@angular/material/card'
import { MatIconModule } from '@angular/material/icon'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatChipsModule } from '@angular/material/chips'
import { MatDatepickerModule } from '@angular/material/datepicker'
import { MatDialogModule } from '@angular/material/dialog'
import { MatExpansionModule } from '@angular/material/expansion'
import { MatGridListModule } from '@angular/material/grid-list'
import { MatSortModule } from '@angular/material/sort'
import { MatInputModule } from '@angular/material/input'
import { MatListModule } from '@angular/material/list'
import { MatMenuModule } from '@angular/material/menu'
import { MatNativeDateModule } from '@angular/material/core'
import { MatRippleModule } from '@angular/material/core'
import { MatPaginatorModule } from '@angular/material/paginator'
import { MatProgressBarModule } from '@angular/material/progress-bar'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { MatRadioModule } from '@angular/material/radio'
import { MatSelectModule } from '@angular/material/select'
import { MatSidenavModule } from '@angular/material/sidenav'
import { MatSliderModule } from '@angular/material/slider'
import { MatSlideToggleModule } from '@angular/material/slide-toggle'
import { MatSnackBarModule } from '@angular/material/snack-bar'
// import { FavoritesComponent } from './components/favorites/favorites.component';
import { FlagsMenuComponent } from './components/flags-menu/flags-menu.component'
import { FullScreenComponent } from './components/fullscreen/fullscreen.component'
import { MenuService } from './components/menu/menu.service'
// import { HorizontalMenuComponent } from './components/menu/horizontal-menu/horizontal-menu.component';
import { VerticalMenuComponent } from './components/menu/vertical-menu/vertical-menu.component'
import { MessagesService } from './components/messages/messages.service'
import { MessagesComponent } from './components/messages/messages.component'
import { SidenavComponent } from './components/sidenav/sidenav.component'
import { FlexLayoutModule } from '@angular/flex-layout'
import { PipesModule } from './pipes/pipes.module'
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar'
import { UserMenuComponent } from './components/user-menu/user-menu.component'
import { TraversalModule } from 'angular-traversal'

@NgModule({
  imports: [
    CommonModule,
    TraversalModule,
    FormsModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatTreeModule,
    MatToolbarModule,
    MatTooltipModule,
    MatStepperModule,
    PipesModule,
    PerfectScrollbarModule,
  ],
  exports: [
    ApplicationsComponent,
    TopInfoContentComponent,
    // FavoritesComponent,
    FlagsMenuComponent,
    FullScreenComponent,
    // HorizontalMenuComponent,
    VerticalMenuComponent,
    MessagesComponent,
    SidenavComponent,
    UserMenuComponent,
  ],
  declarations: [
    ApplicationsComponent,
    TopInfoContentComponent,
    // FavoritesComponent,
    FlagsMenuComponent,
    FullScreenComponent,
    // HorizontalMenuComponent,
    VerticalMenuComponent,
    MessagesComponent,
    SidenavComponent,
    UserMenuComponent,
  ],
  providers: [],
})
export class ThemeModule {
  static forRoot(): ModuleWithProviders {
    {
      return {
        ngModule: ThemeModule,
        providers: [AppSettings, MenuService, MessagesService],
      }
    }
  }
}
