import { Component, OnInit, OnDestroy } from '@angular/core'
import { Grange } from 'grange'
import { Subscription } from 'rxjs'
import { adminMenu, adminMenuHierarchy } from './menu'
import { User } from 'app/views/users/interfaces'

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss'],
})
export class AdminComponent implements OnInit, OnDestroy {
  public isAuthenticated = false
  public user: User

  private menu = adminMenu
  private menuHierarchy = adminMenuHierarchy
  private subs: Array<Subscription> = []

  constructor(private grange: Grange) {}

  ngOnInit() {
    this.grange.core.auth.getUser().subscribe(
      (user) => {
        this.user = user
        this.isAuthenticated = true
      },
      (err) => {
        this.isAuthenticated = false
        if (err.status === 401) {
          this.grange.traverser.traverse('./@@login')
        }
      }
    )
  }

  ngOnDestroy() {
    this.subs.map((sub) => sub.unsubscribe())
  }
}
