import { Component, OnInit, Inject } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { SharingResponse } from '@guillotinaweb/grange-core'
import { Group } from 'app/views/groups/interfaces'
import { Grange } from 'grange'
import { Sort } from '@angular/material/sort'

interface GroupAccess {
  selected: boolean
  group: Group
  disabled: boolean
}

@Component({
  selector: 'app-manage-project-access-dialog',
  templateUrl: './manage-project-access-dialog.component.html',
  styleUrls: ['./manage-project-access-dialog.component.scss'],
})
export class ManageProjectAccessDialogComponent implements OnInit {
  public list: Array<GroupAccess>

  protected searchVal = ''
  public get search(): string {
    return this.searchVal
  }
  public set search(val: string) {
    this.searchVal = val
    this.setData()
  }
  public sort: Sort = {
    active: 'title',
    direction: 'desc',
  }
  protected sortDataVal: Array<GroupAccess> = []
  public get sortData(): Array<GroupAccess> {
    return this.sortDataVal
  }
  public set sortData(val: Array<GroupAccess>) {
    this.sortDataVal = val
  }
  public filtredList: Array<GroupAccess>

  constructor(
    public dialogRef: MatDialogRef<ManageProjectAccessDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { groups: Array<Group>; sharing: SharingResponse; client: string },
    public grange: Grange
  ) {
    this.list = data.groups.map((group) => {
      const isSelected = data.sharing.entries.some(
        (entrie) => entrie.type === 'group' && entrie.id === group.id
      )
      return {
        selected: isSelected,
        group,
        disabled: isSelected,
      }
    })
    this.sortChange(this.sort)
    this.setData()
  }

  ngOnInit(): void {}
  public close(ok: boolean) {
    const added = this.list
      .filter((item) => item.selected && !item.disabled)
      .map((item) => item.group)
    this.dialogRef.close({ ok, added })
  }

  public sortChange(sort: Sort) {
    this.sort = sort
    const data = this.list.slice()
    if (!sort.active || sort.direction === '') {
      this.sortData = data
      return
    }
    this.list = data.sort((a: GroupAccess, b: GroupAccess) => {
      const isAsc = sort.direction === 'asc'
      if (!a.group[sort.active]) {
        return -1 * (isAsc ? 1 : -1)
      }
      if (!b.group[sort.active]) {
        return 1 * (isAsc ? 1 : -1)
      }
      return (
        (a.group[sort.active].toLowerCase() < b.group[sort.active].toLowerCase() ? -1 : 1) *
        (isAsc ? -1 : 1)
      )
    })
    this.setData()
  }
  public setData() {
    this.filtredList = this.list.filter((item) => {
      const description = JSON.parse(item.group.description)
      if (description && description['Client']) {
        return (description['clientsSelected'] || []).includes(this.data.client)
      }
      return item.group.title.toLowerCase().includes(this.search.toLowerCase())
    })
  }
}
