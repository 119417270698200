import { Component, Renderer2 } from '@angular/core'
import { Settings } from './theme/app.settings.model'
import { AppSettings } from './theme/app.settings'
import { Subscription } from 'rxjs'
import { Grange, GrangeViews } from 'grange'
import { APIService } from '@guillotinaweb/grange-core'
import { XListViews } from './traversal'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  public settings: Settings
  isAuthenticated = false
  private subs: Array<Subscription> = []
  public isLoading: boolean

  constructor(
    public appSettings: AppSettings,
    private renderer: Renderer2,
    private grange: Grange,
    private xlistsViews: XListViews,
    // private grangeViews: GrangeViews,
    public api: APIService
  ) {
    this.settings = this.appSettings.settings
    this.renderer.addClass(document.body, 'app')
    this.renderer.addClass(document.body, this.settings.theme)
  }

  ngOnInit() {
    // this.grangeViews.initialize();
    this.xlistsViews.initialize()

    const sub = this.grange.core.auth.isAuthenticated.subscribe((auth) => {
      this.isAuthenticated = auth.state
    })
    this.subs.push(sub)
  }

  ngOnDestroy() {
    this.subs.map((sub: Subscription) => {
      sub.unsubscribe()
    })
  }
}
