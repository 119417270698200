import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core'
import { GrangeFile } from '@guillotinaweb/grange-core'

@Component({
  selector: 'app-file-selector',
  templateUrl: './file-selector.component.html',
  styleUrls: ['./file-selector.component.scss'],
})
export class FileSelectorComponent implements OnInit {
  @Input() public label = 'File'
  @Input() public readOnly = false
  @Input() public file: GrangeFile
  @Output() public fileChange: EventEmitter<GrangeFile> = new EventEmitter<GrangeFile>()

  public get attachment(): string {
    return this.file ? this.file.filename : ''
  }

  constructor() {}

  ngOnInit(): void {}

  public setFile($event) {
    if ($event.target.files && $event.target.files.length) {
      let newAttachment: File
      newAttachment = $event.target.files[0]
      const reader = new FileReader()
      reader.readAsDataURL(newAttachment)
      reader.onload = () => {
        if (typeof reader.result === 'string') {
          this.file = {
            'content-type': newAttachment.type,
            filename: newAttachment.name,
            data: reader.result.split(',')[1],
            encoding: reader.result.split(';')[1].split(',')[0],
          }
          this.fileChange.emit(this.file)
        }
      }
    }
  }
  public deleteFile() {
    this.file = null
    this.fileChange.emit(this.file)
  }
}
