import { Component, OnInit } from '@angular/core'
import { BaseView, Grange } from 'grange'
import { MatDialog } from '@angular/material/dialog'
import { ClientsAddDialogComponent } from './clients-add-dialog/clients-add-dialog.component'

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.scss'],
})
export class ClientsComponent extends BaseView implements OnInit {
  protected query = { portal_type: 'abb.orders.client', metadata_fields: 'modified' }
  public search: string

  constructor(public grange: Grange, public dialog: MatDialog) {
    super(grange)
  }

  ngOnInit() {}

  updateQuery() {
    const newQuery = { portal_type: 'abb.orders.client', metadata_fields: 'modified' }
    if (this.search) {
      Object.assign(newQuery, { SearchableText: this.search })
    }
    this.query = newQuery
  }

  newClient() {
    this.dialog
      .open(ClientsAddDialogComponent, { minWidth: 500 })
      .afterClosed()
      .subscribe((result) => {
        if (!result || !result.ok) {
          return
        }
        this.grange.core.resource.create('', result.model).subscribe((response) => {
          this.updateQuery()
        })
      })
  }
}
