import { Component, OnInit, Inject } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'

export interface FormDialogData {
  mode: 'view' | 'edit' | 'add'
  model: any
  path?: string
}

@Component({
  selector: 'app-dialog-form',
  templateUrl: './dialog-form.component.html',
  styleUrls: ['./dialog-form.component.scss'],
})
export class DialogFormComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<DialogFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: FormDialogData
  ) {}

  ngOnInit() {}

  finish(ok: boolean, result: any) {
    this.dialogRef.close({ save: ok, model: result })
  }
}
