import { Component, OnInit } from '@angular/core'
import { BaseView, Grange } from 'grange'

@Component({
  selector: 'app-pre-order',
  templateUrl: './pre-order.component.html',
  styleUrls: ['./pre-order.component.scss'],
})
export class PreOrderComponent extends BaseView implements OnInit {
  protected query = { portal_type: 'abb.orders.order' }
  protected filters = ['search', 'Creator', 'delivery', 'state']

  path: string
  loaded = false

  constructor(public grange: Grange) {
    super(grange)
  }

  ngOnInit() {
    const sub = this.contextPath.subscribe((path) => {
      this.path = path

      const newQuery = {
        portal_type: 'abb.orders.order',
        metadata_fields: ['modified', 'delivery'],
        // path: {
        //   query: path,
        //   depth: 3
        // },
        // fullobjects: true,
      }
      this.query = newQuery
      this.loaded = true
    })
  }
}
