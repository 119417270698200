import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core'
import { AppSettings } from '../../../app.settings'
import { Settings } from '../../../app.settings.model'
import { MenuService } from '../menu.service'
import { Menu } from '../menu.model'
import { Grange } from 'grange'

@Component({
  selector: 'app-vertical-menu',
  templateUrl: './vertical-menu.component.html',
  styleUrls: ['./vertical-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class VerticalMenuComponent implements OnInit {
  private menuItemsVal: Array<Menu> = []
  @Input() public activeMenuItem: Menu
  @Input() get menuItems(): Array<Menu> {
    return this.menuItemsVal
  }
  set menuItems(val: Array<Menu>) {
    this.menuItemsVal = val
    this.parentMenu = this.menuItems.filter((item) => item.parentId == this.menuParentId)
  }
  private menuParentIdVal: number
  @Input() get menuParentId(): number {
    return this.menuParentIdVal
  }
  set menuParentId(val: number) {
    this.menuParentIdVal = val
    this.parentMenu = this.menuItems.filter((item) => item.parentId == this.menuParentId)
  }
  parentMenu: Array<Menu>
  public settings: Settings
  constructor(public appSettings: AppSettings, public menuService: MenuService) {
    this.settings = this.appSettings.settings
  }

  ngOnInit() {}

  onClick(menuId) {
    this.menuService.toggleMenuItem(menuId)
    this.menuService.closeOtherSubMenus(this.menuItems, menuId)
  }

  isActive(menuItem) {
    return this.activeMenuItem ? menuItem.id == this.activeMenuItem.id : false
  }
}
