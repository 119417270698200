import { Component, OnDestroy, OnInit } from '@angular/core'
import { Grange } from 'grange'
import { TraverserSelectors } from '@guillotinaweb/ngx-state-traverser'
import { Resource, GrangeAction } from '@guillotinaweb/grange-core'
import { takeUntil, take } from 'rxjs/operators'
import { Subject } from 'rxjs'
import { select } from '@ngrx/store'
import { ConfirmDialogComponent } from 'app/shared/dialogs/confirm-dialog/confirm-dialog.component'
import { MatDialog } from '@angular/material/dialog'

@Component({
  selector: 'app-actions',
  templateUrl: './actions.component.html',
  styleUrls: ['./actions.component.scss'],
})
export class ActionsComponent implements OnInit, OnDestroy {
  destroy = new Subject()
  context = TraverserSelectors.TraverserContext<Resource>(this.grange.store).pipe(
    takeUntil(this.destroy)
  )
  contextPath: string
  parentPath: string
  public get exportPackUrl(): string {
    return `${this.grange.core.api.getFullPath(this.contextPath)}/exportPack`
  }
  constructor(public grange: Grange, public dialog: MatDialog) {}

  ngOnInit() {
    this.grange.store
      .pipe(takeUntil(this.destroy), select(TraverserSelectors.getContextPath))
      .subscribe((contextPath) => {
        this.contextPath = contextPath
      })
    this.grange.store
      .pipe(takeUntil(this.destroy), select(TraverserSelectors.getParentPath))
      .subscribe((parentPath) => {
        this.parentPath = parentPath
      })
  }

  ngOnDestroy() {
    this.destroy.next()
    this.destroy.complete()
  }

  public exportPack() {
    const url = `${this.grange.core.api.getFullPath(this.contextPath)}/exportPack`
    window.open(url, '_blank')
  }

  public exportOrder() {
    const url = `${this.grange.core.api.getFullPath(this.contextPath)}/xlsx`
    window.open(url, '_blank')
  }

  public exportCR() {
    const url = `${this.grange.core.api.getFullPath(this.contextPath)}/exportCR`
    window.open(url, '_blank')
  }

  public copy() {
    this.context.pipe(take(1)).subscribe((context) => {
      const dialog = this.dialog.open(ConfirmDialogComponent, {
        data: { title: 'Are you sure you want to copy?' },
      })
      dialog.afterClosed().subscribe((res: boolean) => {
        if (!res) {
          return
        }
        this.grange.core.api
          .post(this.parentPath + '/@copy', {
            source: context.UID,
          })
          .subscribe((copied) => {
            this.grange.traverser.traverse(copied[0].target + '/@@edit')
          })
      })
    })
  }

  public delete() {
    this.context.pipe(take(1)).subscribe((context) => {
      if (confirm('Delete ' + context.title)) {
        const parent = context['@id'].split('/').slice(0, -1).join('/')
        this.grange.core.resource.delete(context['@id']).subscribe(() => {
          this.grange.traverser.traverse(parent)
        })
      }
    })
  }
}
