import { Component, OnInit } from '@angular/core'
import { BaseView, Grange } from 'grange'

@Component({
  selector: 'app-report-total',
  templateUrl: './report-total.component.html',
  styleUrls: ['./report-total.component.scss'],
})
export class ReportTotalComponent extends BaseView implements OnInit {
  protected file = '/reportTotal'
  constructor(public grange: Grange) {
    super(grange)
  }

  ngOnInit() {}

  public download() {
    window.open(this.grange.core.api.getFullPath(this.file), '_blank')
  }
}
