import { Component, OnInit } from '@angular/core'
import { BaseView, Grange } from 'grange'
import { Pack } from '../../models/pack.model'
import { BaseItem, ConfigurationService } from '@guillotinaweb/grange-core'
import { ThrowStmt } from '@angular/compiler'

@Component({
  selector: 'app-manage-pack',
  templateUrl: './manage-pack.component.html',
  styleUrls: ['./manage-pack.component.scss'],
})
export class ManagePackComponent extends BaseView implements OnInit {
  pack: Pack
  pack_id: string
  client: BaseItem
  loaded: boolean = false
  mode: string = 'view'
  safe: boolean = true
  loadingDuplicate: boolean = false

  constructor(public grange: Grange, protected config: ConfigurationService) {
    super(grange)
    this.safe = true
  }

  view() {
    this.mode = 'view'
  }

  edit() {
    this.mode = 'edit'
  }

  goPlone() {
    const url = this.config.get('BACKEND_URL')
    this.contextPath.subscribe((path) => {
      window.open(url + path + '/folder_contents', '_blank')
    })
  }

  duplicate() {
    const payload = {
      source: this.pack['@id'],
    }
    const client_path = this.client['@id'].split('/')
    const client_id = client_path[client_path.length - 1]
    this.loadingDuplicate = true
    this.grange.core.api.post('/' + client_id + '/@copy', payload).subscribe((res) => {
      const url = res[0].target
      this.grange.core.api
        .patch(url, { title: 'Copy of ' + this.pack.title })
        .subscribe((res) => this.grange.traverser.traverse(url + '/@@manage-pack'))
    })
  }

  ngOnInit(): void {
    this.context.subscribe((res) => {
      const url_elements = res['@id'].split('/')
      this.pack = res as unknown as Pack
      this.pack_id = url_elements[url_elements.length - 1]
      this.client = this.pack.parent
      this.loaded = true
    })
  }

  onFinish(event) {
    this.grange.traverser.traverse('./@@manage-pack')
  }

  onCancel() {
    this.grange.traverser.traverse('./@@manage-pack')
  }

  onChange(event) {
    if (this.mode === 'edit') {
      this.safe = false
    }
  }
}
