import { Component, OnInit } from '@angular/core'
import { BaseView, Grange } from 'grange'
import { concatMap } from 'rxjs/operators'
import { GrangeType, Resource } from '@guillotinaweb/grange-core'
import { HttpParams } from '@angular/common/http'

@Component({
  selector: 'app-add-admin',
  templateUrl: './add-admin.component.html',
  styleUrls: ['./add-admin.component.scss'],
})
export class AddAdminComponent extends BaseView implements OnInit {
  type: GrangeType
  public get typeTitle(): string {
    return this.type.title === 'ABB Pack' ? 'X-list' : this.type.title
  }
  types: GrangeType[] = []
  schema: any
  editModel?: any
  get typeString(): string {
    const parseId = this.type['@id'].split('/')
    return parseId[parseId.length - 1]
  }

  constructor(public grange: Grange) {
    super(grange)
  }

  ngOnInit(): void {
    this.contextPath
      .pipe(concatMap((path) => this.grange.core.resource.allTypes(path)))
      .subscribe((types) => {
        this.types = types.filter((typeItem: GrangeType) => {
          return typeItem.addable
        })
        const httpParams = new HttpParams({ fromString: window.location.href.split('?')[1] || '' })
        if (httpParams.get('type')) {
          const type = this.types.find((_type: GrangeType) => {
            return _type['@id'].includes(httpParams.get('type'))
          })
          if (type) {
            this.getSchema(type)
          }
        }
      })
  }

  public getSchema(type: GrangeType) {
    this.type = type
    this.grange.core.api.get(type['@id']).subscribe((schema) => {
      this.editModel = { '@type': this.typeString }
      this.schema = schema
    })
  }

  traverse(path: string) {
    this.grange.traverser.traverse(path)
  }
}
