import { Component, OnInit, OnDestroy } from '@angular/core'
import { Grange } from 'grange'
import { LoginInfo } from '@guillotinaweb/grange-core'
import { AppSettings } from 'app/theme/app.settings'

@Component({
  selector: 'app-reset',
  templateUrl: './reset.component.html',
  styleUrls: ['./reset.component.scss'],
})
export class ResetComponent implements OnInit, OnDestroy {
  useToken = false
  error = ''
  isLogged: boolean
  message: string

  constructor(private appSettings: AppSettings, public grange: Grange) {}

  ngOnInit() {
    setTimeout(() => {
      this.appSettings.settings.fixedHeader = false
      this.appSettings.settings.fixedSidenav = false
    })
    this.grange.core.auth.isAuthenticated.subscribe((auth) => (this.isLogged = auth.state))
  }

  onSubmit(data: LoginInfo | { token: string }) {
    this.error = ''
    if (!this.useToken) {
      const info = data as LoginInfo
      this.grange.core.auth.requestPasswordReset(info.login).subscribe(
        (isLogged) => {
          if (isLogged) {
            this.grange.traverser.traverse('/@@welcome')
          } else {
            this.message =
              'An email with a link to reset your password has been sent to you email address'
          }
        },
        (err) => {
          this.error = err.message
        }
      )
    } else if (!!data.token) {
      this.grange.core.auth.setAuthToken(data.token)
      this.grange.traverser.traverse('/@@welcome')
    }
  }

  ngOnDestroy() {
    setTimeout(() => {
      this.appSettings.settings.fixedHeader = true
      this.appSettings.settings.fixedSidenav = true
    })
  }
}
