import { Component, OnInit, Input } from '@angular/core'
import { Grange } from 'grange'
import { MatDialog } from '@angular/material/dialog'
import { ProjectResource } from 'app/models/project.model'
import { BaseItem, SearchResults } from '@guillotinaweb/grange-core'
import { Normalizer } from 'angular-traversal'
import { ManageProjectXlistsDialogComponent } from './manage-project-xlists-dialog/manage-project-xlists-dialog.component'
import { Sort } from '@angular/material/sort'
import { ManageProjectDeleteXlistDialogComponent } from './manage-project-delete-xlist-dialog/manage-project-delete-xlist-dialog.component'
import { ManageProjectAssignXlistDialogComponent } from './manage-project-assign-xlist-dialog/manage-project-assign-xlist-dialog.component'

@Component({
  selector: 'app-manage-project-xlists',
  templateUrl: './manage-project-xlists.component.html',
  styleUrls: ['./manage-project-xlists.component.scss'],
})
export class ManageProjectXlistsComponent implements OnInit {
  private projectVal: ProjectResource
  @Input() get project(): ProjectResource {
    return this.projectVal
  }
  set project(val: ProjectResource) {
    this.projectVal = val
    this.setData()
  }
  protected searchVal = ''
  public get search(): string {
    return this.searchVal
  }
  public set search(val: string) {
    this.searchVal = val
    this.setData()
  }

  public sort: Sort = {
    active: 'title',
    direction: 'desc',
  }
  protected sortDataVal: Array<BaseItem> = []
  public get sortData(): Array<BaseItem> {
    return this.sortDataVal
  }
  public set sortData(val: Array<BaseItem>) {
    this.sortDataVal = val
  }
  public filtredList: Array<BaseItem>

  public data: Array<BaseItem> = []
  public packs: Array<BaseItem> = []
  constructor(public grange: Grange, public dialog: MatDialog, public normailzer: Normalizer) {}

  ngOnInit(): void {
    this.sortChange(this.sort)
    this.loadPacks()
  }

  public sortChange(sort: Sort) {
    this.sort = sort
    const data = this.data.slice()
    if (!sort.active || sort.direction === '') {
      this.sortData = data
      return
    }
    this.data = data.sort((a: BaseItem, b: BaseItem) => {
      const isAsc = sort.direction === 'asc'
      if (!a[sort.active]) {
        return -1 * (isAsc ? 1 : -1)
      }
      if (!b[sort.active]) {
        return 1 * (isAsc ? 1 : -1)
      }
      return (
        (a[sort.active].toLowerCase() < b[sort.active].toLowerCase() ? -1 : 1) * (isAsc ? -1 : 1)
      )
    })
  }
  public setData() {
    if (!this.project || !this.project.templates) {
      return
    }
    this.data = (this.project.templates || [])
      .filter((template) => template.title.toLowerCase().includes(this.search.toLowerCase()))
      .slice()
    this.sortChange(this.sort)
  }

  public isEnabledTemplate(pack: BaseItem) {
    return this.project.enable_templates.some((pack_item) => pack_item['@id'] === pack['@id'])
  }

  public openDeleteDialog(pack: BaseItem) {
    const data = {
      pack,
    }
    const dialog = this.dialog.open(ManageProjectDeleteXlistDialogComponent, {
      data,
      minWidth: 500,
      maxHeight: '80vh',
    })
    dialog.afterClosed().subscribe((result: { ok: boolean; pack: BaseItem }) => {
      if (!result || !result.ok) {
        return
      }
      this.deletePack(pack)
    })
  }

  public openAssignDialog(pack: BaseItem) {
    const data = {
      pack,
    }
    const dialog = this.dialog.open(ManageProjectAssignXlistDialogComponent, {
      data,
      minWidth: 500,
      maxHeight: '80vh',
    })
    dialog.afterClosed().subscribe((result: { ok: boolean; pack: BaseItem }) => {
      if (!result || !result.ok) {
        return
      }
      this.addPacks([pack])
    })
  }

  public openAddDialog() {
    const data = {
      project: this.project,
      packs: this.packs,
    }
    const dialog = this.dialog.open(ManageProjectXlistsDialogComponent, {
      data,
      minWidth: 500,
      maxHeight: '80vh',
    })
    dialog.afterClosed().subscribe((result: { ok: boolean; added: Array<BaseItem> }) => {
      if (!result || !result.ok) {
        return
      }
      this.addPacks(result.added)
    })
  }

  private loadPacks() {
    if (this.project.parent['@id']) {
      const clientPath = this.normailzer.normalize(this.project.parent['@id'])
      const newQuery = {
        portal_type: 'abb.orders.pack',
        path: {
          query: clientPath,
          depth: 1,
        },
        review_state: 'internally-published',
        // fullobjects: true,
      }
      this.grange.core.resource.find(newQuery).subscribe((packs: SearchResults) => {
        this.recursiveSearch(packs)
      })
    }
  }

  public addPacks(packs: BaseItem[]) {
    this.project.enable_templates = [...this.project.enable_templates, ...packs]
    const allProjects = [...(this.project.templates || [])]
    packs.forEach((project) => {
      const find = allProjects.find((proj) => proj['@id'] === project['@id'])
      if (!find) {
        allProjects.push(project)
      }
    })
    this.project.templates = allProjects
    this.grange.core.resource.update(this.project['@id'], this.project).subscribe((project) => {
      this.project = project
    })
  }

  public deletePack(pack: BaseItem) {
    this.project.enable_templates = this.project.enable_templates.filter(
      (projectPack) => projectPack['@id'] !== pack['@id']
    )
    this.grange.core.resource.update(this.project['@id'], this.project).subscribe((project) => {
      this.project = project
    })
  }

  private recursiveSearch(result: SearchResults) {
    this.packs = [...this.packs, ...result.items]
    if (result.batching && result.batching.next) {
      this.grange.core.api.get(result.batching.next).subscribe((next: SearchResults) => {
        this.recursiveSearch(next)
      })
    }
  }
}
