import { NgForm } from '@angular/forms'
import { Grange } from 'grange'
import { EventEmitter, Component, Input, Output, ViewChild, AfterViewInit } from '@angular/core'
import { Observable } from 'rxjs'
import { BaseItem } from '@guillotinaweb/grange-core'

@Component({})
export class BaseFormsComponent<T extends BaseItem> implements AfterViewInit {
  @ViewChild('form', { static: false }) public form: NgForm
  @Input() public mode: 'add' | 'view' | 'edit' = 'view'
  @Input() path: string
  private modelVal: T
  @Input() public get model(): T {
    return this.modelVal
  }
  public set model(val: T) {
    this.modelVal = val
    this.modelChange.emit(val)
    if (this.canUpdateForm) {
      this.updateForm()
    }
  }
  @Output() public modelChange: EventEmitter<T> = new EventEmitter<T>()

  @Output() public afterCancel: EventEmitter<void> = new EventEmitter()
  @Output() public afterSave: EventEmitter<any> = new EventEmitter()

  private canUpdateForm = true

  public get readOnly() {
    return this.mode === 'view'
  }
  public error?: string

  constructor(public grange: Grange) {}

  ngAfterViewInit() {
    setTimeout(() => {
      this.initForm()
    })
  }

  public initForm() {
    this.updateForm()
    this.form.valueChanges.subscribe((value) => {
      if (!!this.model) {
        this.canUpdateForm = false
        this.model = Object.assign(this.model, value)
        this.canUpdateForm = true
      }
    })
  }

  public onCancel() {
    this.afterCancel.emit()
  }
  public onSave() {
    if (this.mode === 'view') {
      return
    }
    if (!this.form || this.form.invalid) {
      return
    }
    if (!this.path && !this.model['@id']) {
      // virtual form can't save
      this.afterSave.emit()
      return
    }
    const save: Observable<any> =
      this.mode === 'add'
        ? this.grange.core.resource.create(this.path, this.model)
        : this.grange.core.resource.save(this.model['@id'], this.model)
    save.subscribe(
      (result) => {
        this.afterSave.emit(result)
      },
      (error) => {
        if (error && error.message) {
          this.error = error.message
        }
      }
    )
  }
  private updateForm() {
    if (this.form) {
      this.form.reset(this.model)
    }
  }
}
