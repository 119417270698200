import { Component, OnInit } from '@angular/core'
import { BaseComponent } from '../base'
import { Grange } from 'grange'
import { Group } from 'app/views/groups/interfaces'
import { Role, Entrie, SharingResponse, RoleValue } from '@guillotinaweb/grange-core'
import { Subscription } from 'rxjs'

@Component({
  selector: 'app-sharing',
  templateUrl: './sharing.component.html',
  styleUrls: ['./sharing.component.scss'],
})
export class SharingComponent extends BaseComponent implements OnInit {
  protected EXLUDE_GROUPS = ['Reviewers', 'Site Administrators', 'AuthenticatedUsers']
  private path: string
  private searchVal: string
  public get search(): string {
    return this.searchVal
  }
  public set search(val: string) {
    this.searchVal = val
    this.loadGroups()
  }
  private groups: Array<Group> = []
  public sharing: SharingResponse
  public data: Array<{
    Id: string
    Title: string
    ABB: RoleValue
    Linebuilder: RoleValue
    Client: RoleValue
    Selected: boolean
  }> = []

  private searchSub: Subscription
  private searchTimer: any
  private updateSub: Subscription
  private updateTimer: any

  constructor(public grange: Grange) {
    super(grange)
  }

  ngOnInit(): void {
    this.loadGroups()
    this.contextPath.subscribe((path: string) => {
      this.path = path
      this.loadSharing()
    })
  }

  private loadSharing() {
    this.grange.core.resource.sharing(this.path, this.search).subscribe((result) => {
      this.sharing = result
      this.setData()
    })
  }

  private loadGroups() {
    clearTimeout(this.searchTimer)
    this.searchTimer = setTimeout(() => {
      if (this.searchSub) {
        this.searchSub.unsubscribe()
      }
      if (!this.search) {
        this.groups = []
        this.setData()
        return
      }
      this.searchSub = this.grange.core.resource.groups(this.search).subscribe((result) => {
        this.groups = result
        this.setData()
      })
    }, 300)
  }

  private setData() {
    if (!this.sharing) {
      return []
    }
    const data: Array<{
      Id: string
      Title: string
      ABB: RoleValue
      Linebuilder: RoleValue
      Client: RoleValue
      Selected: boolean
    }> = []
    this.sharing.entries
      .filter(
        (entrie: Entrie) => entrie.type === 'group' && !this.EXLUDE_GROUPS.includes(entrie.id)
      )
      .map((entrie: Entrie) => {
        const isSelected = !!entrie.roles.ABB || !!entrie.roles.Linebuilder || !!entrie.roles.Client
        data.push({
          Id: entrie.id,
          Title: entrie.title,
          ABB: entrie.roles.ABB,
          Linebuilder: entrie.roles.Linebuilder,
          Client: entrie.roles.Client,
          Selected: isSelected,
        })
      })
    this.groups
      .filter(
        (group) =>
          !this.EXLUDE_GROUPS.includes(group.id) &&
          !data.map((entrie) => entrie.Id).includes(group.id)
      )
      .map((group) => {
        let entrie = {
          Id: group.id,
          Title: group.title,
          ABB: false,
          Linebuilder: false,
          Client: false,
          Selected: false,
        }
        if (!!group.description && !!JSON.parse(group.description)) {
          entrie = Object.assign(entrie, JSON.parse(group.description))
        }
        data.push(entrie)
      })
    this.data = data
  }

  public updateSharing() {
    clearTimeout(this.updateTimer)
    this.updateTimer = setTimeout(() => {
      if (this.updateSub) {
        this.updateSub.unsubscribe()
      }
      const updateSharing: {
        entries: Array<Entrie>
        inherit: boolean
      } = { entries: [], inherit: this.sharing.inherit }
      this.data.map((item) => {
        updateSharing.entries.push({
          id: item.Id,
          type: 'group',
          title: item.Title,
          roles: {
            ABB: item.Selected ? item.ABB : false,
            Linebuilder: item.Selected ? item.Linebuilder : false,
            Client: item.Selected ? item.Client : false,
          },
        })
      })

      this.updateSub = this.grange.core.resource
        .updateSharing(this.path, updateSharing)
        .subscribe((result) => {})
    }, 300)
  }
}
