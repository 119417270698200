import { Component, Inject, OnInit } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { BaseItem } from '@guillotinaweb/grange-core'

@Component({
  selector: 'app-manage-project-assign-xlist-dialog',
  templateUrl: './manage-project-assign-xlist-dialog.component.html',
  styleUrls: ['./manage-project-assign-xlist-dialog.component.scss'],
})
export class ManageProjectAssignXlistDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<ManageProjectAssignXlistDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { pack: BaseItem }
  ) {}

  ngOnInit(): void {}

  public close(ok: boolean) {
    this.dialogRef.close({ ok, pack: this.data.pack })
  }
}
