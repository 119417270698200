import { Component, Input, OnInit } from '@angular/core'
import { BaseItem, SearchResults } from '@guillotinaweb/grange-core'
import { Grange } from 'grange'
import { take } from 'rxjs/operators'

@Component({
  selector: 'app-projects-in-xlists-expanded-item',
  templateUrl: './projects-in-xlists-expanded-item.component.html',
  styleUrls: ['./projects-in-xlists-expanded-item.component.scss'],
})
export class ProjectsInXlistsExpandedItemComponent implements OnInit {
  @Input() public packId = ''
  public isLoadingData: boolean = false
  public items: Array<BaseItem | any> = []

  constructor(private grange: Grange) {}

  ngOnInit(): void {
    const query = {
      portal_type: 'abb.orders.project',
      metadata_fields: ['modified', 'enable_templates'],
    }
    this.isLoadingData = true
    this.grange.core.resource
      .find(query, '/', {})
      .pipe(take(1))
      .subscribe((result: SearchResults) => {
        this.items = []
        this.recursiveSearch(result)
      })
  }

  private recursiveSearch(result: SearchResults) {
    this.items = [...this.items, ...result.items]
    if (result.batching && result.batching.next) {
      this.grange.core.resource
        .get(result.batching.next)
        .pipe(take(1))
        .subscribe((newResult: SearchResults) => {
          this.recursiveSearch(newResult)
        })
    } else {
      this.isLoadingData = false
    }
  }

  getProjects() {
    return this.items.filter((project) =>
      (project.enable_templates || []).some((template) => template['@id'] === this.packId)
    )
  }
}
