import { Component, OnInit, ChangeDetectorRef, AfterViewInit, ViewChild } from '@angular/core'
import { BaseView, Grange } from 'grange'
import { Client } from '../../models/client.model'
import { markForCheck } from '@guillotinaweb/pastanaga-angular'
import { Subscription } from 'rxjs'
import { ConfigurationService } from '@guillotinaweb/grange-core'

@Component({
  selector: 'app-manage-emails',
  templateUrl: './manage-emails.component.html',
  styleUrls: ['./manage-emails.component.scss'],
})
export class ManageEmailsComponent extends BaseView implements OnInit {
  private mailsTimer: any
  public mails: any
  private mailersSub: Subscription
  message: string

  constructor(
    public grange: Grange,
    private cdr: ChangeDetectorRef,
    protected config: ConfigurationService
  ) {
    super(grange)
  }

  ngOnInit() {
    this.updateMails()
  }

  updateMails() {
    clearTimeout(this.mailsTimer)
    this.mailsTimer = setTimeout(() => {
      if (this.mailersSub) {
        this.mailersSub.unsubscribe()
      }
      this.mailersSub = this.grange.core.api
        .get(this.config.get('BACKEND_URL') + '/@showPendingMails')
        .subscribe((mails) => {
          this.mails = mails
          markForCheck(this.cdr)
        })
    }, 300)
  }

  public clean() {
    if (confirm('Are you sure?')) {
      this.grange.core.api
        .get(this.config.get('BACKEND_URL') + '/@clearPendingMails')
        .subscribe((result) => {
          this.message = result
          this.updateMails()
        })
    }
  }

  public send() {
    this.grange.core.api
      .get(this.config.get('BACKEND_URL') + '/@sendPendingMails')
      .subscribe((result) => {
        this.message = result
        this.updateMails()
      })
  }
}
