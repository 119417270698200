import { Component, OnInit, EventEmitter } from '@angular/core'
import { SearchResults } from '@guillotinaweb/grange-core'
import { BaseView, Grange } from 'grange'
import { iif, interval, of } from 'rxjs'
import { switchMap, take, tap } from 'rxjs/operators'

@Component({
  selector: 'app-report-lbxlsx-form',
  templateUrl: './report-lbxlsx-form.component.html',
  styleUrls: ['./report-lbxlsx-form.component.scss'],
})
export class ReportLBxlsxFormComponent extends BaseView implements OnInit {
  protected file = '/@exportAllOrders'
  excelURL: string
  excelTitle: string
  spinner: boolean = false
  emitter = new EventEmitter()
  intervalOrder = interval(1000)
  totalTimeOrders = null
  itemsTotal = 0

  constructor(public grange: Grange) {
    super(grange)
  }

  ngOnInit() {
    // 0.7seg x comanda

    this.excelTitle = 'all_orders.xlsx'
    this.context.subscribe((context) => {
      this.excelTitle = 'Detailed report orders - ' + context.title + '.xlsx'
    })
    this.contextPath
      .pipe(
        tap((path) => (this.excelURL = path + this.file)),
        switchMap((path) => {
          return this.grange.core.resource.find({ portal_type: 'abb.orders.order' }, path, {})
        })
      )
      .subscribe((result: SearchResults) => {
        this.itemsTotal = result.items_total
      })

    this.emitter
      .pipe(
        tap((res: any) => (this.spinner = res)),
        switchMap((res) => iif(() => res, this.intervalOrder, of(0)))
      )
      .subscribe((i) => {
        if (i === 0) {
          this.totalTimeOrders = null
        } else {
          if (i > this.itemsTotal) {
            i = this.itemsTotal - 20
          }
          this.totalTimeOrders = new Date((this.itemsTotal - i) * 1000).toISOString().substr(14, 5)
        }
      })
  }
}
