import { Component, OnInit } from '@angular/core'
import { BaseView, Grange } from 'grange'
import { GrangeType } from '@guillotinaweb/grange-core'
import { HttpParams } from '@angular/common/http'
import { concatMap, take, filter, map } from 'rxjs/operators'

const FIELDS_EXCLUDE = [
  'type_name',
  'uuid',
  'creation_date',
  'modification_date',
  'history',
  'tiles',
  'tiles_layout',
]

@Component({
  selector: 'app-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.scss'],
})
export class AddComponent extends BaseView implements OnInit {
  type: GrangeType
  get typeString(): string {
    const parseId = this.type['@id'].split('/')
    return parseId[parseId.length - 1]
  }
  types: GrangeType[] = []
  schema: any
  editModel?: any
  error?: string
  richTextFields: string[] = []

  constructor(public grange: Grange) {
    super(grange)
  }

  ngOnInit() {
    this.contextPath
      .pipe(concatMap((path) => this.grange.core.resource.allTypes(path)))
      .subscribe((types) => {
        this.types = types.filter((typeItem: GrangeType) => {
          return typeItem.addable
        })
        const httpParams = new HttpParams({ fromString: window.location.href.split('?')[1] || '' })
        if (httpParams.get('type')) {
          const type = this.types.find((_type: GrangeType) => {
            return _type['@id'].includes(httpParams.get('type'))
          })
          if (type) {
            this.getSchema(type)
          }
        }
      })
  }

  onSave() {
    const model = this.editModel
    this.error = ''
    // model['@type'] = this.typeString;
    this.contextPath
      .pipe(
        take(1),
        concatMap((path) => this.grange.core.resource.create(path, model))
      )
      .subscribe(
        (res: any) => this.grange.traverser.traverse(res['@id']),
        (error) => (this.error = error.message || `Something went wrong while creating ${model.id}`)
      )
  }

  public getSchema(type: GrangeType) {
    this.type = type
    this.grange.core.api.get(type['@id']).subscribe((schema) => {
      this.editModel = { '@type': this.typeString }
      this.schema = schema
    })
  }

  updateModel(model: any, flatModel: any): any {
    Object.keys(flatModel).forEach((key) => {
      if (this.richTextFields.includes(key)) {
        flatModel[key] = {
          content_type: 'text/html',
          encoding: 'utf-8',
          data: flatModel[key],
        }
      }
      if (key.includes('.')) {
        const behavior = key.split('.').slice(0, -1).join('.')
        const subKey = key.split('.').slice(-1)[0]
        model[behavior][subKey] = flatModel[key]
      } else {
        model[key] = flatModel[key]
      }
    })

    return model
  }

  onCancel() {
    this.grange.traverser.traverse('.')
  }
}
