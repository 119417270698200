import { Directive } from '@angular/core';

@Directive({ selector: 'pa-dialog-title' })
export class DialogTitleDirective {}

@Directive({ selector: 'pa-dialog-image' })
export class DialogImageDirective {}

@Directive({ selector: 'pa-dialog-footer' })
export class DialogFooterDirective {}
