import { Component } from '@angular/core'
import { BaseView, Grange } from 'grange'

@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss'],
})
export class ViewComponent extends BaseView {
  constructor(public grange: Grange) {
    super(grange)
  }
}
