import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'
import { User } from '../interfaces'
import { Grange } from 'grange'
import { Observable, Subscription, concat } from 'rxjs'
import { Group } from 'app/views/groups/interfaces'
import { finalize } from 'rxjs/operators'

@Component({
  selector: 'app-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss'],
})
export class UserFormComponent implements OnInit {
  protected EXLUDE_GROUPS = ['Reviewers', 'Site Administrators', 'AuthenticatedUsers']
  @Input() mode: 'view' | 'edit' | 'add' = 'view'
  @Input() user: User

  @Output() cancel: EventEmitter<User> = new EventEmitter<User>()
  @Output() save: EventEmitter<User> = new EventEmitter<User>()
  public error: string

  constructor(public grange: Grange) {}

  ngOnInit() {}

  onCancel() {
    this.cancel.emit(this.user)
  }

  onSave() {
    this.user.username = this.user.email
    let saveUser: Observable<any>
    if (!this.user.portrait) {
      this.user.portrait = {}
    }
    if (!!this.user.roles) {
      delete this.user.roles
    }
    if (this.mode === 'add') {
      // @ts-ignore
      this.user.sendPasswordReset = true
      // @ts-ignore
      delete this.user.password
      saveUser = this.grange.core.resource.createUser(this.user)
      alert(
        'Registration email has now been sent to the user. Please add the user to a group immediately'
      )
    } else if (this.mode === 'edit') {
      saveUser = this.grange.core.resource.updateUser(this.user)
    }
    if (!saveUser) {
      return
    }
    saveUser.subscribe(
      (user) => {
        this.user = Object.assign(this.user, user)
        this.save.emit(this.user)
      },
      (error) => {
        if (!!error && !!error.message) {
          this.error = error.message
        }
      }
    )
  }
}
