import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'price',
})
export class PricePipe implements PipeTransform {
  transform(price: number, currency?: string): string {
    if (price === null) {
      return ''
    }
    const stringPrice = String(Number(price))
    let resultString = ''
    let end = stringPrice.length
    do {
      resultString = `${stringPrice.slice(Math.max(end - 3, 0), end)} ${resultString}`
      end -= 3
    } while (end > 0)
    resultString = resultString.trim()
    if (currency) {
      resultString += ` ${currency}`
    }
    return resultString
  }
}
