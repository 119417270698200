import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'
import { Group } from '../interfaces'
import { Role } from '@guillotinaweb/grange-core'
import { Grange } from 'grange'
import { Observable } from 'rxjs'

@Component({
  selector: 'app-group-form',
  templateUrl: './group-form.component.html',
  styleUrls: ['./group-form.component.scss'],
})
export class GroupFormComponent implements OnInit {
  @Input() mode: 'view' | 'edit' | 'add' = 'view'
  private groupVal: Group
  @Input() get group(): Group {
    return this.groupVal
  }
  set group(val: Group) {
    this.groupVal = val
  }

  public roles: Array<Role>

  @Output() cancel: EventEmitter<Group> = new EventEmitter<Group>()
  @Output() save: EventEmitter<Group> = new EventEmitter<Group>()

  public error: string
  constructor(public grange: Grange) {}

  ngOnInit() {
    this.grange.core.resource.roles().subscribe((roles) => (this.roles = roles))
  }

  onCancel() {
    this.cancel.emit(this.group)
  }

  onSave() {
    let saveGroup: Observable<any>
    const result = Object.assign({}, this.group)
    result.users = undefined
    if (this.mode === 'add') {
      result.groupname = result.title.replace(/[^a-zA-Z1-9]/g, '').toLowerCase()
      saveGroup = this.grange.core.resource.createGroup(result)
    } else if (this.mode === 'edit') {
      saveGroup = this.grange.core.resource.updateGroup(result)
    }
    if (!saveGroup) {
      return
    }
    saveGroup.subscribe(
      (group) => {
        this.group = group
        this.save.emit(this.group)
      },
      (error) => {
        if (!!error && !!error.message) {
          this.error = error.message
        }
      },
      () => {}
    )
  }
}
