import { Component, OnInit } from '@angular/core'
import { BaseView, Grange } from 'grange'
import { concatMap, map, take } from 'rxjs/operators'

const FIELDS_EXCLUDE = [
  'type_name',
  'uuid',
  'creation_date',
  'modification_date',
  'history',
  'tiles',
  'tiles_layout',
]

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss'],
})
export class EditComponent extends BaseView implements OnInit {
  typeString: string
  error?: string
  schema?: any
  editModel?: any
  richTextFields: string[] = []

  constructor(public grange: Grange) {
    super(grange)
  }

  ngOnInit() {
    this.context
      .pipe(
        concatMap((context) =>
          this.grange.core.resource.type(context['@type']).pipe(map((schema) => [schema, context]))
        )
      )
      .subscribe(([schema, context]) => {
        this.typeString = context['@type']
        this.editModel = JSON.parse(JSON.stringify(context))
        this.schema = schema
      })
  }

  updateModel(model: any, flatModel: any): any {
    Object.keys(flatModel).forEach((key) => {
      if (this.richTextFields.includes(key)) {
        flatModel[key] = {
          content_type: 'text/html',
          encoding: 'utf-8',
          data: flatModel[key],
        }
      }
      if (key.includes('.')) {
        const behavior = key.split('.').slice(0, -1).join('.')
        const subKey = key.split('.').slice(-1)[0]
        model[behavior][subKey] = flatModel[key]
      } else {
        model[key] = flatModel[key]
      }
    })

    return model
  }

  onSave() {
    if (!this.error) {
      this.context.pipe(take(1)).subscribe((context) => {
        const model = this.updateModel(context, this.editModel)
        this.grange.core.resource.save(context['@id'], model).subscribe(() => {
          this.grange.traverser.traverse('.')
        })
      })
    }
  }

  onCancel() {
    this.grange.traverser.traverse('.')
  }
}
