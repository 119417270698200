import { Injectable } from '@angular/core'
import { DefaultWidgetRegistry } from 'ngx-schema-form'
import { StringWidgetComponent } from './string-widget/string-widget.component'
import { ObjectWidgetComponent } from './object-widget/object-widget.component'
import { TextAreaWidgetComponent } from './text-area-widget/text-area-widget.component'
import { CheckboxWidgetComponent } from './checkbox-widget/checkbox-widget.component'
import { ButtonWidgetComponent } from './button-widget/button-widget.component'
import { SelectWidgetComponent } from './select-widget/select-widget.component'
import { ArrayWidgetComponent } from './array-widget/array-widget.component'

@Injectable()
export class WidgetRegestryService extends DefaultWidgetRegistry {
  constructor() {
    super()

    this.register('string', StringWidgetComponent)
    this.register('object', ObjectWidgetComponent)
    this.register('search', StringWidgetComponent)
    this.register('tel', StringWidgetComponent)
    this.register('url', StringWidgetComponent)
    this.register('email', StringWidgetComponent)
    this.register('password', StringWidgetComponent)
    this.register('date', StringWidgetComponent)
    this.register('date-time', StringWidgetComponent)
    this.register('time', StringWidgetComponent)
    this.register('dict', StringWidgetComponent)
    this.register('integer', StringWidgetComponent)
    this.register('number', StringWidgetComponent)
    this.register('textarea', TextAreaWidgetComponent)
    this.register('select', SelectWidgetComponent)
    this.register('boolean', CheckboxWidgetComponent)
    this.register('checkbox', CheckboxWidgetComponent)
    this.register('array', ArrayWidgetComponent)

    this.register('button', ButtonWidgetComponent)

    this.setDefaultWidget(StringWidgetComponent)
  }
}
