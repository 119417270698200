import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'
import { Group } from '../interfaces'
import { SharingResponse, RoleValue, SearchResults, BaseItem } from '@guillotinaweb/grange-core'
import { Grange } from 'grange'
import { take } from 'rxjs/operators'
import { PAGE_SIZE_OVERRIDED } from 'app/core'

@Component({
  selector: 'app-group-sharing',
  templateUrl: './group-sharing.component.html',
  styleUrls: ['./group-sharing.component.scss'],
})
export class GroupSharingComponent implements OnInit {
  public clients: Array<BaseItem | any> = []

  private groupVal: Group
  @Input() get group(): Group {
    return this.groupVal
  }
  set group(val: Group) {
    this.groupVal = val
    this.setData()
  }
  @Output() cancel: EventEmitter<Group> = new EventEmitter<Group>()
  @Output() save: EventEmitter<Group> = new EventEmitter<Group>()
  public sharing: SharingResponse
  public data: Array<{
    ABB: RoleValue
    Linebuilder: RoleValue
    Client: RoleValue
    clientsSelected: Array<string>
  }> = []
  public error: string

  constructor(public grange: Grange) {}

  ngOnInit(): void {
    this.grange.core.resource
      .find(
        { portal_type: 'abb.orders.client', metadata_fields: 'id', b_size: PAGE_SIZE_OVERRIDED },
        '/',
        {}
      )
      .pipe(take(1))
      .subscribe((result: SearchResults) => {
        this.clients = result.items
      })
  }

  private setData() {
    if (!this.group) {
      return []
    }
    let data = {
      ABB: false,
      Linebuilder: false,
      Client: false,
      clientsSelected: [],
    }
    if (!!this.group.description) {
      data = Object.assign(data, JSON.parse(this.group.description))
    }
    this.data = [data]
  }

  public onCancel() {
    this.save.emit(this.group)
    return
  }

  public onSave() {
    if (!this.data.length) {
      this.save.emit(this.group)
      return
    }
    const result: Group = Object.assign(this.group, { description: JSON.stringify(this.data[0]) })
    this.grange.core.resource.updateGroup(result).subscribe(
      (group) => {
        this.group = group
        this.save.emit(this.group)
      },
      (error) => {
        if (!!error && !!error.message) {
          this.error = error.message
        }
      },
      () => {}
    )
    return
  }

  public clientChecked(client): boolean {
    return this.data[0]['clientsSelected'].includes(client.id)
  }

  public checkClient(check: boolean, client) {
    if (check) {
      this.data[0]['clientsSelected'].push(client.id)
    } else {
      this.data[0]['clientsSelected'] = this.data[0]['clientsSelected'].filter(
        (clientId) => clientId !== client.id
      )
    }
  }
}
