import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'pad',
})
export class PadPipe implements PipeTransform {
  transform(value: string | number, length: number, pad = '0'): string {
    let result = String(value)
    while (result.length < length) {
      result = `${pad}${result}`
    }
    return result
  }
}
