import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'itemIcon',
})
export class ItemIconPipe implements PipeTransform {
  protected iconsMap: { [type: string]: string } = {
    file: 'insert_drive_file',
    folder: 'folder',
    collection: 'all_inbox',
    image: 'image',
    link: 'link',
    event: 'event',
    'abb.orders.changerequest': 'import_export',
    'abb.orders.pack': 'web',
    'abb.orders.order': 'shopping_cart',
  }
  transform(item: { '@type': string }): string {
    const lowerCaseType = item['@type'].toLocaleLowerCase()
    if (this.iconsMap[lowerCaseType]) {
      return this.iconsMap[lowerCaseType]
    }
    return 'help'
  }
}
