import { Component } from '@angular/core'
import { BaseFormsComponent } from '../base.forms.component'
import { PackMaingroup } from '../../models/pack.model'

@Component({
  selector: 'app-main-group',
  templateUrl: './main-group.component.html',
  styleUrls: ['./main-group.component.scss'],
})
export class MainGroupComponent extends BaseFormsComponent<PackMaingroup> {
  public get attachment(): string {
    return this.model.fitxer ? this.model.fitxer.filename : ''
  }

  public setFile($event) {
    if ($event.target.files && $event.target.files.length) {
      let newAttachment: File
      newAttachment = $event.target.files[0]
      const reader = new FileReader()
      reader.readAsDataURL(newAttachment)
      reader.onload = () => {
        if (typeof reader.result === 'string') {
          this.model.fitxer = {
            'content-type': newAttachment.type,
            filename: newAttachment.name,
            data: reader.result.split(',')[1],
            encoding: reader.result.split(';')[1].split(',')[0],
          }
        }
      }
    } else {
      this.model.fitxer = undefined
    }
  }
}
