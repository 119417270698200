import { Component, OnInit } from '@angular/core'
import { BaseView, Grange } from 'grange'
import { Subscription } from 'rxjs'
import { BaseItem } from '@guillotinaweb/grange-core'
import { Project } from 'app/models/project.model'

@Component({
  selector: 'app-all-templates',
  templateUrl: './all-templates.component.html',
  styleUrls: ['./all-templates.component.scss'],
})
export class AllTemplatesComponent extends BaseView implements OnInit {
  private subs: Array<Subscription> = []
  public templates: Array<BaseItem>

  constructor(public grange: Grange) {
    super(grange)
  }

  ngOnInit() {
    const sub = this.context.subscribe((context) => {
      this.templates = ((<Project>(<unknown>context)).enable_templates || [])
        .filter((pack) => pack.review_state !== 'rejected')
        .sort((a: BaseItem, b: BaseItem) => {
          return a.title.localeCompare(b.title)
        })
    })
    this.subs.push(sub)
  }

  goToTemplate(template: BaseItem) {
    const ident = template['@id'].split('/').slice(-1)
    this.grange.traverser.traverse('./@@templates?ident=' + ident)
  }
}
