import {
  Component,
  OnInit,
  ViewChild,
  HostListener,
  ViewChildren,
  QueryList,
  Input,
  SimpleChanges,
} from '@angular/core'
import { PerfectScrollbarDirective, PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar'
import { AppSettings } from '../../theme/app.settings'
import { Settings } from '../../theme/app.settings.model'
import { rotate } from '../../theme/utils/app-animation'
import { Menu } from '../../theme/components/menu/menu.model'
import { Grange } from 'grange'
import { Project } from 'app/models/project.model'
import { User } from 'app/views/users/interfaces'
import { select } from '@ngrx/store'
import { TraverserSelectors } from '@guillotinaweb/ngx-state-traverser'
import { Normalizer, Target } from 'angular-traversal'
import { BaseComponent } from '../base'
import { switchMap, filter, map } from 'rxjs/operators'
@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss'],
  animations: [rotate],
})
export class PagesComponent extends BaseComponent implements OnInit {
  @ViewChild('sidenav', { static: false }) sidenav: any
  // @ViewChild('backToTop', { static: true }) backToTop: any;
  @ViewChildren(PerfectScrollbarDirective) pss: QueryList<PerfectScrollbarDirective>
  public optionsPsConfig: PerfectScrollbarConfigInterface = {}
  public settings: Settings
  public showSidenav: boolean = false
  public showInfoContent: boolean = false
  public toggleSearchBar: boolean = false
  private defaultMenu: string //declared for return default menu when window resized
  public menus = ['vertical', 'horizontal']
  public menuOption: string
  public menuTypes = ['default', 'compact', 'mini']
  public menuTypeOption: string
  public base_url: string
  public project: Project
  public activeMenuItem: Menu
  public target: Target
  @Input() title: string = ''
  @Input() user: User
  public get isAdminUser(): boolean {
    return !!this.user && !!this.user.roles && this.user.roles.includes('Manager')
  }
  @Input() public menu: Array<Menu>
  @Input() public menuHierarchy
  @Input() public isAdminSite = false
  @Input() public wideContent = false
  constructor(
    public appSettings: AppSettings,
    public grange: Grange,
    private normalizer: Normalizer
  ) {
    super(grange)
    this.settings = this.appSettings.settings
  }

  ngOnInit() {
    window.scroll(0, 0)
    this.optionsPsConfig.wheelPropagation = false
    if (window.innerWidth <= 960) {
      this.settings.menu = 'vertical'
      this.settings.sidenavIsOpened = false
      this.settings.sidenavIsPinned = false
    }
    this.contextPath
      .pipe(
        map((path) => this.normalizer.normalize(path).split('/').slice(0, 3).join('/')),
        filter((projectName) => projectName && projectName !== 'null' && projectName !== '/'),
        switchMap((projectName) => this.grange.core.resource.get(projectName))
      )
      .subscribe((data) => {
        this.project = data
      })

    this.menuOption = this.settings.menu
    this.menuTypeOption = this.settings.menuType
    this.defaultMenu = this.settings.menu

    this.grange.store.pipe(select(TraverserSelectors.getTarget)).subscribe((target) => {
      this.target = target
      this.activeMenuItem = this.getActiveMenuItem()
    })
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.hasOwnProperty('menu')) {
      this.activeMenuItem = this.getActiveMenuItem()
    }
  }

  goAdmin() {
    this.grange.traverser.traverse('/@@admin')
  }

  public toggleSidenav() {
    this.sidenav.toggle()
  }

  public chooseMenu() {
    this.settings.menu = this.menuOption
    this.defaultMenu = this.menuOption
    if (this.menuOption === 'horizontal') {
      this.settings.fixedSidenav = false
    }
  }

  public chooseMenuType() {
    this.settings.menuType = this.menuTypeOption
  }

  public changeTheme(theme) {
    this.settings.theme = theme
  }

  public closeInfoContent(showInfoContent) {
    this.showInfoContent = !showInfoContent
  }

  @HostListener('window:resize')
  public onWindowResize(): void {
    if (window.innerWidth <= 960) {
      this.settings.sidenavIsOpened = false
      this.settings.sidenavIsPinned = false
      this.settings.menu = 'vertical'
    } else {
      this.defaultMenu === 'horizontal'
        ? (this.settings.menu = 'horizontal')
        : (this.settings.menu = 'vertical')
      this.settings.sidenavIsOpened = true
      this.settings.sidenavIsPinned = true
    }
  }

  public onPsScrollY(event) {
    // (event.target.scrollTop > 300) ? this.backToTop.nativeElement.style.display = 'flex' : this.backToTop.nativeElement.style.display = 'none';
  }

  // public scrollToTop() {
  //   this.pss.forEach(ps => {
  //     if (ps.elementRef.nativeElement.id == 'main') {
  //       ps.scrollToTop(0, 250);
  //     }
  //   });
  // }

  public goToHome() {
    this.grange.traverser.traverse('/@@welcome')
  }

  private getActiveMenuItem() {
    if (!this.target) return null

    const activeItem = this.menu.find((item) => item.routerLink == this.target.path)
    if (activeItem) {
      return activeItem
    } else {
      const currentView = this.menuHierarchy.find((item) => {
        const matchType = item.type == this.target.context['@type']
        const matchView = item.view == '*' || item.view == this.target.view
        return matchType && matchView
      })
      return currentView ? this.menu.find((item) => item.id == currentView.parentMenuItem) : null
    }
  }
}
