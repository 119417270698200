import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit } from '@angular/core'
import { Grange } from 'grange'
import { LoginInfo, ConfigurationService } from '@guillotinaweb/grange-core'
import { AppSettings } from 'app/theme/app.settings'
import { NgForm } from '@angular/forms'

export class ResetInfo {
  login: string
  password: string
  password2: string
}

@Component({
  selector: 'app-real-reset',
  templateUrl: './real-reset.component.html',
  styleUrls: ['./real-reset.component.scss'],
})
export class RealResetComponent implements OnInit, OnDestroy, AfterViewInit {
  useToken = false
  error = ''
  isLogged: boolean
  login: string
  @ViewChild('form', { static: false }) public form: NgForm

  constructor(
    private appSettings: AppSettings,
    public grange: Grange,
    protected config: ConfigurationService
  ) {}

  ngOnInit() {
    setTimeout(() => {
      this.appSettings.settings.fixedHeader = false
      this.appSettings.settings.fixedSidenav = false
    })
    this.grange.core.auth.isAuthenticated.subscribe((auth) => (this.isLogged = auth.state))
  }

  onSubmit(data: ResetInfo) {
    this.error = ''
    const info = data as ResetInfo

    if (info.password !== info.password2) {
      this.error = 'Password does not match'
    }
    this.grange.core.api
      .post(this.config.get('BACKEND_URL') + '/@users/' + this.login + '/reset-password ', {
        reset_token: this.grange.traverser.target.value.path
          .split('/')
          .slice(-1)
          .pop()
          .split('?')[0],
        new_password: info.password,
      })
      .subscribe(
        (res) => {
          this.grange.traverser.traverse('/@@login')
        },
        (err) => {
          this.error = err.message
        }
      )
  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    this.grange.traverser.target.subscribe((res) => {
      if (res.query) {
        this.login = res.query.get('userid')
      }
    })
  }
  ngOnDestroy() {
    setTimeout(() => {
      this.appSettings.settings.fixedHeader = true
      this.appSettings.settings.fixedSidenav = true
    })
  }
}
