import { Component, OnInit } from '@angular/core'
import { BaseViewModel, Grange } from 'grange'
import { ProjectResource } from 'app/models/project.model'

@Component({
  selector: 'app-add-change-request',
  templateUrl: './add-change-request.component.html',
  styleUrls: ['./add-change-request.component.scss'],
})
export class AddChangeRequestComponent extends BaseViewModel<ProjectResource> implements OnInit {
  private path: string

  constructor(public grange: Grange) {
    super(grange)
  }

  ngOnInit(): void {
    this.contextPath.subscribe((path) => (this.path = path))
  }

  public create() {
    this.grange.core.api.post(`${this.path}/@createCR`, {}).subscribe((result: { url: string }) => {
      this.grange.traverser.traverse(result.url + '/@@addChangeRequestStep2')
    })
  }
}
