import { Component, OnInit, Input, AfterViewInit, ChangeDetectionStrategy } from '@angular/core'
import { Sort, SortDirection } from '@angular/material/sort'
import { PageEvent } from '@angular/material/paginator'
import { SelectionModel } from '@angular/cdk/collections'
import { Grange } from 'grange'
import { combineLatest } from 'rxjs'
import { STATE_TITLES } from '../../pipes/state.pipe'
import { animate, state, style, transition, trigger } from '@angular/animations'

interface Item {
  '@id': string
  '@type': string
  [key: string]: string
}

const FORWARDORDER = {
  // ORDER
  'draft-pre-order': 'asend-to-client',
  'order-accepted': 'asend-to-abb',
  'sent-to-abb': 'asend-to-acknowledgement',
  '4-in-progress': 'accept-order',
  'acknowledgement-accepted': 'asend-to-production-delivery',
  'order-in-production': 'adeliver-order',
  'order-waiting-to-be-delivered': 'agoods-recieved',
  'delivery-approved': 'asend-to-invoicing-process',
  // CR
  'linebuilder-draft': 'asend-to-client-for-submission',
  '-1-submitted-to-the-client': 'asend-to-submission-abb',
  'submitted-to-abb': 'asend-to-client-approval',
  'waiting-to-be-approved-by-client': 'approved-by-client',
  approved: 'aexecute-cr',
  'in-execution': 'afinalize',
}

const BACKWARDORDER = {
  // ORDER
  'order-accepted': 'reject',
  'sent-to-abb': 'reject-to-client',
  '4-in-progress': 'send-back-to-abb',
  'acknowledgement-accepted': 'send-back-one-step',
  'order-in-production': 'send-back-to-acknowleggement',
  'order-waiting-to-be-delivered': 'send-back-to-production',
  'delivery-approved': 'send-to-order-waiting-to-be-delivered',
  invoicing: 'send-to-invoiced',
  // CR
  'linebuilder-draft': 'zcancel-cr',
  '-1-submitted-to-the-client': 'send-back-to-linebuilder-draft',
  'submitted-to-abb': 'send-back-to-client-for-submission',
  'waiting-to-be-approved-by-client': 'send-back-to-submitted-to-abb',
  approved: 'send-back-to-waiting-approve',
  'in-execution': 'send-back-to-approved',
  finalized: 'send-back-to-in-execution',
}

@Component({
  selector: 'app-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class ItemComponent implements OnInit, AfterViewInit {
  protected acceptedColumns = [
    'expandedRow',
    'number',
    'title',
    'image',
    'Creator',
    'implementation_date',
    'price',
    'review_state',
    'modified',
    'delivery',
  ]
  public columns: Array<string> = []
  protected typeMap = {
    'abb.orders.order': 'order',
    'abb.orders.pack': 'pack',
    'abb.orders.changerequest': 'changerequest',
  }
  public currentPath: string

  @Input() public clean = false
  @Input() public expandedComponent: string | null = null

  private checkboxVal = false
  @Input() get checkbox(): boolean {
    return this.checkboxVal
  }
  set checkbox(val: boolean) {
    this.checkboxVal = val
    this.updateColumns()
  }
  @Input() public nameTitle = 'Title'
  @Input() public nameTitleValue = 'Title'
  @Input() public excludeColumns = []

  @Input() public initialSort = null
  @Input() public initialSortDir = null

  @Input() public action = ''

  private itemsVal: Array<Item>
  @Input() public get items(): Array<Item> {
    return this.itemsVal
  }
  public set items(val: Array<Item>) {
    this.itemsVal = this.processInput(val)
    this.updateColumns()
    this.applySort(this.sort)
  }

  selection = new SelectionModel<Item>(true, [])
  protected sortDataVal: Array<Item> = []
  public get sortData(): Array<Item> {
    return this.sortDataVal
  }
  public set sortData(val: Array<Item>) {
    this.sortDataVal = val
    this.pageIndex = 0
    this.paginate()
  }
  public pageData: Array<Item> = []
  public pageIndex = 0
  public pageSize = 10

  public sort: Sort = {
    active: '',
    direction: '',
  }

  expandedElement: any | null

  constructor(public grange: Grange) {}

  ngOnInit() {
    this.grange.traverser.target.subscribe((res) => {
      this.currentPath = res.path
    })

    if (this.clean) {
      this.columns = ['title']
    }
    const savedSortActive = localStorage.getItem(
      `${this.currentPath}-sort-active-${this.nameTitle}`
    )
    const savedSortDirection = localStorage.getItem(
      `${this.currentPath}-sort-direction-${this.nameTitle}`
    )

    if (savedSortActive) {
      this.sort.active = savedSortActive
      this.sort.direction = (savedSortDirection as SortDirection) || 'asc'

      this.sortChange(this.sort)
    } else if (this.initialSort) {
      this.sort.active = this.initialSort
      this.sort.direction = this.initialSortDir ? this.initialSortDir : 'asc'
      this.sortChange(this.sort)
    }

    const savedPageIndex = localStorage.getItem(`${this.currentPath}-page-index-${this.nameTitle}`)
    const savedPageSize = localStorage.getItem(`${this.currentPath}-page-size-${this.nameTitle}`)
    if (savedPageIndex || savedPageSize) {
      this.pageIndex = savedPageIndex ? parseInt(savedPageIndex, 0) : 0
      this.pageSize = savedPageSize ? parseInt(savedPageSize, 0) : 10
      this.paginate()
    }
  }

  ngAfterViewInit() {}

  isAllSelected() {
    const numSelected = this.selection.selected.length
    const numRows = this.items.length
    return numSelected === numRows
  }

  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.items.forEach((row) => this.selection.select(row))
  }

  processInput(values) {
    const new_items = values.map((item: Item) => {
      const value = Object.assign({}, item)
      value.orig_review_state = item.review_state
      if (item.review_state && item.review_state in STATE_TITLES) {
        value.review_state = STATE_TITLES[item.review_state]
      }
      return value
    })
    return new_items
  }

  updateColumns() {
    this.columns = this.acceptedColumns
      .filter((column: string) => {
        return this.items.some((item: Item) => {
          return Object.keys(item).some((key: string) => {
            return !this.excludeColumns.includes(key) && key === column
          })
        })
      })
      .slice()
    if (this.checkbox) {
      this.columns.unshift('select')
    }
    if (this.expandedComponent !== null) {
      this.columns = ['expandedRow', ...this.columns]
    }
  }
  checkboxLabel(row?: Item): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`
  }

  public sortChange(sort: Sort) {
    this.sort = sort

    localStorage.setItem(`${this.currentPath}-sort-active-${this.nameTitle}`, sort.active)
    localStorage.setItem(`${this.currentPath}-sort-direction-${this.nameTitle}`, sort.direction)
    this.applySort(sort)
  }

  private applySort(sort: Sort) {
    const data = this.items.slice()
    if (!sort.active || sort.direction === '') {
      this.sortData = data
      return
    }
    this.sortData = data.sort((a: Item, b: Item) => {
      const isAsc = sort.direction === 'asc'
      if (a[sort.active] === null || a[sort.active] === undefined || a[sort.active] === '') {
        return 1 * (isAsc ? 1 : -1)
      }
      if (b[sort.active] === null || b[sort.active] === undefined || b[sort.active] === '') {
        return -1 * (isAsc ? 1 : -1)
      }
      let firstItem: string | number = a[sort.active]

      if (typeof firstItem === 'string') {
        firstItem = firstItem.toLowerCase()
      }
      let secondItem: string | number = b[sort.active]
      if (typeof secondItem === 'string') {
        secondItem = secondItem.toLowerCase()
      }

      return (firstItem < secondItem ? -1 : 1) * (isAsc ? 1 : -1)
    })
  }

  public pageChange(page: PageEvent) {
    localStorage.setItem(
      `${this.currentPath}-page-index-${this.nameTitle}`,
      page.pageIndex.toString()
    )
    localStorage.setItem(
      `${this.currentPath}-page-size-${this.nameTitle}`,
      page.pageSize.toString()
    )

    this.pageIndex = page.pageIndex
    this.pageSize = page.pageSize
    this.paginate()
  }

  public paginate() {
    const sorted = this.sortData.slice(
      this.pageIndex * this.pageSize,
      (this.pageIndex + 1) * this.pageSize
    )
    this.pageData = sorted.map((item: any) => ({
      ...item,
      expandedRow: this.expandedComponent !== null,
    }))
  }

  public review_next() {
    const operations = []
    for (const item of this.selection.selected) {
      if (item.orig_review_state in FORWARDORDER) {
        operations.push(
          this.grange.core.resource.transition(item['@id'], FORWARDORDER[item.orig_review_state])
        )
      }
    }
    combineLatest(operations).subscribe((pair) => window.location.reload())
  }

  public review_back() {
    const operations = []
    for (const item of this.selection.selected) {
      if (item.orig_review_state in BACKWARDORDER) {
        operations.push(
          this.grange.core.resource.transition(item['@id'], BACKWARDORDER[item.orig_review_state])
        )
      }
    }
    combineLatest(operations).subscribe((pair) => window.location.reload())
  }
}
