import { Component, OnInit, HostListener, OnDestroy, Input } from '@angular/core'
import {
  Order,
  ViewOrder,
  Comanda,
  Options,
  MainGroup,
  GroupOptions,
} from '../../models/order.model'
import { BaseFormsComponent } from '../base.forms.component'
import { Grange } from 'grange'
import { Normalizer } from 'angular-traversal'
import { PlatformLocation } from '@angular/common'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss'],
})
export class OrderComponent extends BaseFormsComponent<Order> implements OnInit, OnDestroy {
  public comanda: Array<Comanda>
  public commandaErrors: Array<{ uid: string; error: string }> = []
  @Input() public viewOrder: ViewOrder
  terminator: Subject<void> = new Subject()
  force: boolean = false

  public get isInProgress(): boolean {
    if (this.model) {
      return !['draft-pre-order', 'order-accepted'].includes(this.model.review_state)
    }
    return false
  }

  @HostListener('window:beforeunload', ['$event'])
  handleBeforeUnload($event: any) {
    if (this.hasUnsavedData()) {
      $event.returnValue = true
    }
  }

  constructor(
    public grange: Grange,
    public normalizer: Normalizer,
    private location: PlatformLocation
  ) {
    super(grange)
    this.location.onPopState(() => {
      if (this.hasUnsavedData()) {
        return false
      }
    })
  }

  ngOnInit() {
    this.loadViewOrder()
    this.grange.traverser.beforeTraverse
      .pipe(takeUntil(this.terminator))
      .subscribe(([canTraverse, path]) => {
        if (this.hasUnsavedData()) {
          alert('Before leaving the Order either Cancel or Save it')
          canTraverse.next(false)
        } else {
          canTraverse.next(true)
        }
      })
  }

  ngOnDestroy() {
    this.terminator.next()
  }

  hasUnsavedData() {
    if (this.mode === 'edit') {
      return (this.form.dirty || this.form.invalid) && !this.force
    }
  }

  private loadViewOrder() {
    let path = ''
    if (this.path) {
      path = this.path
    } else if (this.model['@id']) {
      path = this.model['@id']
    }
    if (this.viewOrder) {
      this.setComanda()
    }
  }

  private optionsCallback(
    mainGroups: Array<MainGroup>,
    callback: (option: Options, mainGroup: MainGroup, groupOption?: GroupOptions) => void
  ) {
    mainGroups.map((mainGroup) => {
      mainGroup.option_groups.map((groupOption) => {
        groupOption.options.map((option) => {
          callback(option, mainGroup, groupOption)
        })
      })
      mainGroup.options.map((option) => {
        callback(option, mainGroup)
      })
    })
  }

  public getOptionPrice(option: Options): number {
    const amount = this.getOptionAmount(option)
    const optionPrice = Number(option.price) * Number(amount)
    const delegatedAmount = Number(amount) ? 1 : 0
    let optionPercent = 0
    let isPercent = false
    const percentGroups: Array<{ title: string; percent: number }> = this.getPercentGroups(option)
    percentGroups.map((percentGroup) => {
      if (!isPercent) {
        optionPercent += (optionPrice * Number(percentGroup.percent)) / 100
        isPercent = true
      }
      this.optionsCallback(
        this.viewOrder.options.filter((group) => group.title === percentGroup.title),
        (percentOption, group) => {
          optionPercent +=
            (delegatedAmount *
              Number(percentOption.price) *
              Number(this.getOptionAmount(percentOption)) *
              Number(percentGroup.percent)) /
            100
        }
      )
    })
    return isPercent ? Math.round(optionPercent) : optionPrice
  }

  public getTotalPrice(): number {
    if (!this.viewOrder) {
      return 0
    }
    let result = 0
    this.optionsCallback(this.viewOrder.options, (option: Options, mainGroup: MainGroup) => {
      result += this.getOptionPrice(option)
    })
    return result
  }

  private setComanda() {
    const newComanda = this.model.comanda ? this.model.comanda.slice() : []
    this.comanda = newComanda
    this.validateComanda()
    this.optionsCallback(this.viewOrder.options, (option, mainGroup, groupOption) => {
      if (!groupOption || !groupOption.group) {
        if (option.readonly) {
          const amount = option.checkbox ? 1 : option.amount
          this.setOptionAmount(option, amount)
        }
      }
    })
  }

  public optionChecked(option: Options): boolean {
    return (this.comanda || []).some(
      (commanda) => commanda.option === option.uid && commanda.amount === 1
    )
  }

  public getOptionAmount(option: Options): number {
    if (!this.comanda) {
      return 0
    }
    let result = 0
    const find = (this.comanda || []).find((comanda) => comanda.option === option.uid)
    if (find) {
      result = Number(find.amount)
    }
    return result
  }

  public setOptionAmount(option: Options, value: number, group?: { uid: string }) {
    // if (value) {
    let find = (this.comanda || []).find((comanda) => option.uid === comanda.option)
    if (!find) {
      find = {
        group: group ? group.uid : null,
        date: null,
        option: option.uid,
        amount: 0,
      }
      this.comanda.push(find)
    }
    if (value === 0) {
      find.option = ''
    }
    find.amount = value
    // } else {
    //   this.comanda = this.comanda.filter(comanda => option.uid !== comanda.option);
    // }
    this.validateComanda()
  }

  public getGroupOption(group: { uid: string }): Options {
    const comandaGroup = (this.comanda || []).find((comanda) => comanda.group === group.uid)
    if (!comandaGroup) {
      return undefined
    }
    let result: Options
    this.optionsCallback(this.viewOrder.options, (option, mainGroup) => {
      if (option.uid === comandaGroup.option) {
        result = option
      }
    })
    return result
  }

  public setGroupOption(group: { uid: string }, option: Options) {
    this.error = undefined
    if (!option) {
      this.comanda = (this.comanda || []).filter((comanda) => comanda.group !== group.uid)
      return
    }
    let comandaGroup = (this.comanda || []).find((comanda) => comanda.group === group.uid)
    if (!comandaGroup) {
      comandaGroup = {
        date: null,
        option: null,
        amount: null,
        group: group.uid,
      }
      this.comanda.push(comandaGroup)
    }
    comandaGroup.option = option.uid
    comandaGroup.amount = option.amount
    this.setOptionAmount(option, 1)
    this.validateComanda()
  }
  private validateComanda() {
    this.commandaErrors = []
    const comandaOptions: Array<Options> = []
    ;(this.comanda || []).map((comanda) => {
      this.optionsCallback(this.viewOrder.options, (option, mainGroup) => {
        if (option.uid === comanda.option && !!comanda.amount) {
          comandaOptions.push(option)
        }
      })
    })
    comandaOptions.map((comandaOption) => {
      if (comandaOption.logic) {
        comandaOption.logic.split(',').map((logicOption: string) => {
          if (logicOption.startsWith('needs')) {
            const amount = logicOption.split(' ')[1]
            const ref = logicOption.split(' ')[2]
            const refOption = this.getOptionByRef(ref)
            if (refOption) {
              const ok = (this.comanda || []).some(
                (comanda2) => comanda2.option === refOption.uid && comanda2.amount >= Number(amount)
              )
              if (!ok) {
                this.commandaErrors.push({
                  uid: refOption.uid,
                  error: `"${comandaOption.title}" needs ${amount} "${refOption.title}"`,
                })
              }
            }
          } else if (logicOption.startsWith('no')) {
            const ref = logicOption.split(' ')[1]
            const refOption = this.getOptionByRef(ref)
            if (refOption) {
              const ok = !(this.comanda || []).some((comanda2) => comanda2.option === refOption.uid)
              if (!ok) {
                this.commandaErrors.push({
                  uid: refOption.uid,
                  error: `"${comandaOption.title}" is incompatible with "${refOption.title}"`,
                })
              }
            }
          }
        })
      }
    })
  }

  public getOptionErrors(option: Options) {
    return this.commandaErrors.filter((error) => error.uid === option.uid)
  }

  private getOptionByRef(ref: string): Options {
    let result: Options
    this.optionsCallback(this.viewOrder.options, (option, mainGroup) => {
      if (option.ref === ref) {
        result = option
      }
    })
    return result
  }
  private getPercentGroups(option: Options): Array<{ title: string; percent: number }> {
    const percentGroups: Array<{ title: string; percent: number }> = []
    if (!option.logic) {
      return percentGroups
    }
    option.logic.split(',').map((logicOption: string) => {
      if (logicOption.startsWith('price')) {
        const percent = Number(logicOption.split(' - ')[2].replace('%', ''))
        const title = logicOption.split(' - ')[1]
        percentGroups.push({ title, percent })
      }
    })
    return percentGroups
  }

  public onCancel() {
    if (
      this.mode === 'edit' &&
      this.model.description === 'temp' &&
      (this.model.review_state === 'draft-pre-order' ||
        this.model.review_state === 'order-accepted' ||
        this.model.review_state === 'sent-to-abb')
    ) {
      this.force = true
      this.grange.core.resource.delete(this.model['@id']).subscribe((result) => {
        this.grange.traverser.traverse('../')
      })
    } else {
      super.onCancel()
    }
  }

  public onSave() {
    if (this.mode === 'view') {
      return
    }
    if (!this.form || this.form.invalid) {
      return
    }

    for (const mg of this.viewOrder.options) {
      for (const group of mg.option_groups) {
        if (group.group && group.mandatory) {
          const found = (this.comanda || []).filter((comanda) => comanda.group === group.uid)
          if (found.length === 0) {
            this.error = 'Please select an option from ' + group.title
            return
          }
          if (found[0].amount === 0) {
            this.error = 'Please select an option from ' + group.title
            return
          }
        }
      }
    }
    if (!this.path && !this.model['@id']) {
      // virtual form can't save
      this.afterSave.emit()
      return
    }
    const data: any = {}
    data.title = this.model.title
    data.description = ''

    const pattern = /(\d{2})\/(\d{2})\/(\d{4})/
    // data.date = this.model.date
    const date = new Date(this.model.date.split('T')[0].replace(pattern, '$3-$2-$1'))
    data.date = date.toDateString()
    // const date = new Date(this.model.date);
    // date.setUTCHours(24);
    // data.date = date.toLocaleDateString()
    // const delivery = new Date(this.model.delivery);
    // debugger;
    // delivery.setUTCHours(24);
    if (this.model.delivery) {
      if (typeof this.model.delivery === typeof new Date()) {
        // @ts-ignore
        data.delivery = this.model.delivery.toDateString()
      } else {
        const delivery = new Date(this.model.delivery.split('T')[0].replace(pattern, '$3-$2-$1'))
        data.delivery = delivery.toDateString()
      }
    }
    if (this.model.abb_delivery) {
      const abb_delivery = new Date(this.model.abb_delivery)
      // abb_delivery.setUTCHours(24);
      data.abb_delivery = abb_delivery.toDateString()
    } else {
      data.abb_delivery = null
    }
    data.robotno = this.model.robotno
    data.line = this.model.line
    data.project = this.model.project
    data.station = this.model.station
    data.idnumber = this.model.idnumber
    data.observations = this.model.observations
    data.changeNote = this.model.changeNote
    const comandaDate = new Date().toDateString()
    ;(this.comanda || []).map((comanda) => (comanda.date = comandaDate))
    data.comanda = this.comanda
    this.grange.core.api.patch(`${this.model['@id']}/@editOrder`, data).subscribe(
      (result) => {
        this.model = Object.assign(this.model, result)
        this.afterSave.emit()
      },
      (error) => {
        if (error && error.message) {
          this.error = error.message
        }
      }
    )
  }
}
