import {
  Component,
  AfterViewInit,
  ElementRef,
  ViewChild,
  Renderer2,
  ContentChild,
} from '@angular/core'

@Component({
  selector: 'app-button-multi-options',
  templateUrl: './button-multi-options.component.html',
  styleUrls: ['./button-multi-options.component.scss'],
})
export class ButtonMultiOptionsComponent implements AfterViewInit {
  private isToggle = false
  private value = false
  @ContentChild('toggler', { read: ElementRef, static: false }) toggler: ElementRef
  @ContentChild('content', { read: ElementRef, static: false }) content: ElementRef

  constructor(private renderer: Renderer2) {}

  ngAfterViewInit() {
    this.init()
  }

  private init() {
    this.value = false
    this.render()
    this.renderer.listen(this.toggler.nativeElement, 'click', ($event: MouseEvent) => {
      this.toggle($event)
    })
    this.renderer.listen(window, 'click', ($event: MouseEvent) => {
      if (!this.isToggle) {
        this.value = false
        this.render()
      }
    })
  }

  public toggle($event: MouseEvent) {
    this.isToggle = true
    this.value = !this.value
    this.render()
    setTimeout(() => {
      this.isToggle = false
    })
  }

  private render() {
    if (this.value) {
      this.renderer.removeStyle(this.content.nativeElement, 'display')
    } else {
      this.renderer.setStyle(this.content.nativeElement, 'display', 'none')
    }
  }
}
