import { Component, OnInit } from '@angular/core'
import { BaseView, Grange } from 'grange'
import { filter, takeLast, first } from 'rxjs/operators'
import { UsersComponent } from '../users/users.component'

@Component({
  selector: 'app-orders-review',
  templateUrl: './orders-review.component.html',
  styleUrls: ['./orders-review.component.scss'],
})
export class OrdersReviewComponent extends BaseView implements OnInit {
  public query
  activeRoles: string

  constructor(public grange: Grange) {
    super(grange)
  }

  ngOnInit() {
    this.contextPath.subscribe((path) => {
      this.grange.core.api.get(path + '/@rolesIm').subscribe((roles) => {
        if (!roles) {
          return
        }
        const states = new Set<string>()
        this.activeRoles = ''

        if (roles.indexOf('Manager') > -1) {
          this.activeRoles += ' Manager '
          states.add('sent-to-abb')
          states.add('order-accepted')
          states.add('draft-pre-order')
        }
        if (roles.indexOf('ABB') > -1) {
          this.activeRoles += ' ABB '
          states.add('sent-to-abb')
        }
        if (roles.indexOf('Client') > -1) {
          this.activeRoles += ' Client '
          states.add('order-accepted')
        }
        if (roles.indexOf('Linebuilder') > -1) {
          this.activeRoles += ' Linebuilder '
          states.add('draft-pre-order')
        }
        this.query = {
          portal_type: 'abb.orders.order',
          review_state: { query: Array.from(states) },
          metadata_fields: ['modified', 'delivery', 'Creator'],
        }
      })
    })
  }
}
