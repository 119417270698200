import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core'
import { Grange } from 'grange'
import { SearchResults } from '@guillotinaweb/grange-core'
import { BaseComponent } from 'develop/grange/projects/grange/src/lib/components/base'
import { Normalizer } from 'angular-traversal'
import { take } from 'rxjs/operators'

@Component({
  selector: 'app-project-selector',
  templateUrl: './project-selector.component.html',
  styleUrls: ['./project-selector.component.scss'],
})
export class ProjectSelectorComponent extends BaseComponent implements OnInit {
  private projects: Array<any> = []
  private projectSelectedVal: string
  public get projectSelected(): string {
    return this.projectSelectedVal
  }
  public set projectSelected(val: string) {
    this.projectSelectedVal = val
    this.grange.traverser.traverse(`${val}${this.action}`)
  }

  @Input() action = ''

  public get openProjects(): Array<any> {
    return this.projects.filter((item) => item.review_state === 'open')
  }
  public get closedProjects(): Array<any> {
    return this.projects.filter((item) => item.review_state !== 'open')
  }

  constructor(
    public grange: Grange,
    private normalizer: Normalizer,
    private cdr: ChangeDetectorRef
  ) {
    super(grange)
  }

  ngOnInit() {
    this.grange.core.resource
      .find({ portal_type: 'abb.orders.project' })
      .pipe(take(1))
      .subscribe((result: SearchResults) => {
        this.recursiveSearch(result)
        this.cdr.markForCheck()
      })
    this.contextPath.subscribe((path) => {
      const projectName = this.normalize(path).split('/').slice(0, 3).join('/')
      this.projectSelectedVal = projectName
    })
  }

  private recursiveSearch(result: SearchResults) {
    this.projects = [...this.projects, ...result.items].sort((a, b) => (a.title > b.title ? 1 : -1))
    if (result.batching && result.batching.next) {
      this.grange.core.resource
        .get(result.batching.next)
        .pipe(take(1))
        .subscribe((newResult: SearchResults) => {
          this.recursiveSearch(newResult)
        })
    }
  }

  public normalize(path: string): string {
    return this.normalizer.normalize(path)
  }
}
