import { COMMA, ENTER } from '@angular/cdk/keycodes'
import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { FormControl, NgForm } from '@angular/forms'
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete'
import { MatChipInputEvent } from '@angular/material/chips'
import { BaseItem, SearchResults, SearchResultsExtended } from '@guillotinaweb/grange-core'
import { Normalizer } from 'angular-traversal'
import { Grange } from 'grange'
import { Subscription } from 'rxjs'
import { BaseFormsComponent } from '../base.forms.component'
import { PackOption } from './option'

interface Option extends BaseItem {
  ref: string
}

@Component({
  selector: 'app-option',
  templateUrl: './option.component.html',
  styleUrls: ['./option.component.scss'],
})
export class OptionComponent
  extends BaseFormsComponent<PackOption>
  implements AfterViewInit, OnInit, OnDestroy
{
  @ViewChild('logicForm', { static: false }) public logicForm: NgForm
  @ViewChild('amountForm', { static: false }) public amountForm: NgForm
  @ViewChild('supplierInput', { static: false }) public supplierInput: ElementRef<HTMLInputElement>
  supplierCtrl = new FormControl()
  // public supplierInput: string;
  public packOptions: Array<Option> = []
  public packGroupOptions: Array<BaseItem> = []
  public get requiredAmountRequirePercent(): boolean {
    if (!this.model || !this.model.logic) {
      return false
    }
    const logicArray = this.model.logic.split(',')
    return logicArray.some((option) => option.startsWith('price'))
  }

  public get attachment(): string {
    return this.model.fitxer ? this.model.fitxer.filename : ''
  }
  public suppliers: Array<{ token: string; title: string }> = []
  public suppliersFilterd: Array<{ token: string; title: string }> = []
  public get selectedSuppliers(): Array<string> {
    if (!this.model || !this.model.supplier) {
      return []
    }
    return this.model.supplier.split(', ').filter((sup) => sup !== '')
  }
  private canUpdateLogicForm = true
  private canUpdateAmountForm = true
  public requireOptions: Array<string> = []
  protected subs: Array<Subscription> = []
  public separatorKeysCodes: number[] = [ENTER, COMMA]
  constructor(public grange: Grange, private normalizer: Normalizer) {
    super(grange)
  }

  ngOnInit() {
    this.subs.push(
      this.modelChange.subscribe(() => {
        if (this.canUpdateLogicForm && this.canUpdateAmountForm) {
          this.updateLogicForm()
          this.updateAmountForm()
        }
      })
    )
    this.grange.core.resource.get('/@vocabularies/abb.orders.suppliers').subscribe((result) => {
      this.suppliers = []
      this.recursiveVocabularies(result)
    })
    this.supplierCtrl.valueChanges.subscribe((text: string | null) => {
      this.suppliersFilterd = this.suppliers.filter((supplier) => {
        return !text || supplier.title.toLowerCase().includes(text.toLowerCase())
      })
    })
  }

  private recursiveVocabularies(result) {
    this.suppliers = [...this.suppliers, ...result.items]
    if (result.batching && result.batching.next) {
      this.grange.core.resource.get(result.batching.next).subscribe((newResult: SearchResults) => {
        this.recursiveVocabularies(newResult)
      })
    }
  }

  ngOnDestroy() {
    this.subs.map((sub) => sub.unsubscribe())
  }

  ngAfterViewInit() {
    super.ngAfterViewInit()
    this.getOptions()
    this.getGroupOptions()
    setTimeout(() => {
      this.updateLogicForm()
      this.updateAmountForm()
      this.subs.push(
        this.logicForm.valueChanges.subscribe((value) => {
          if (!!this.model) {
            const logicFormArray: Array<string> = []
            if (value.require) {
              Object.keys(value.require).map((ref) => {
                if (value.require[ref]) {
                  logicFormArray.push(`needs ${value.require[ref]} ${ref}`)
                }
              })
            }
            if (value.exclude) {
              value.exclude.map((ref) => {
                logicFormArray.push(`no ${ref}`)
              })
            }
            if (value.require_percent && value.require_percent.options) {
              value.require_percent.options.map((title) => {
                logicFormArray.push(`price - ${title} - ${value.require_percent.amount}%`)
              })
            }
            const logicForm = logicFormArray.join(',')
            this.canUpdateLogicForm = false
            this.model = Object.assign(this.model, { logic: logicForm })
            this.canUpdateLogicForm = true
          }
        })
      )
      this.subs.push(
        this.amountForm.valueChanges.subscribe((value) => {
          if (!!this.model) {
            const modelChange = {
              checkbox: ['checkbox', 'checkboxPreselected'].includes(value.type),
              amount: value.type === 'definedQty' ? value.definedQtyAmount : null,
              readonly: ['definedQty', 'checkboxPreselected'].includes(value.type),
            }
            this.canUpdateAmountForm = false
            this.model = Object.assign(this.model, { ...modelChange })
            this.canUpdateAmountForm = true
          }
        })
      )
    })
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.model.supplier = [...this.selectedSuppliers, event.option.viewValue].join(', ')
    this.supplierInput.nativeElement.value = ''
  }

  remove(supplier: string): void {
    this.model.supplier = this.selectedSuppliers.filter((sup) => sup !== supplier).join(', ')
  }

  addOnBlur(event: FocusEvent) {
    const target: HTMLElement = event.relatedTarget as HTMLElement
    if (!target || target.tagName !== 'MAT-OPTION') {
      const matChipEvent: MatChipInputEvent = {
        input: this.supplierInput.nativeElement,
        value: this.supplierInput.nativeElement.value,
      }
      this.add(matChipEvent)
    }
  }

  add(event: MatChipInputEvent): void {
    const input = event.input
    const value = event.value

    // Add our fruit
    if ((value || '').trim()) {
      this.model.supplier = [...this.selectedSuppliers, value.trim()].join(', ')
    }

    // Reset the input value
    if (input) {
      input.value = ''
    }

    this.supplierCtrl.setValue(null)
  }

  private getOptions() {
    let path: string
    if (this.model && this.model['@id']) {
      path = this.model['@id']
    } else if (this.path) {
      path = this.path
    }
    if (!path) {
      return
    }
    path = this.normalizer.normalize(path).split('/').slice(0, 3).join('/')
    this.subs.push(
      this.grange.core.resource
        .find({
          portal_type: 'abb.orders.option',
          path,
          metadata_fields: ['ref'],
          sort_on: 'getId',
        })
        .subscribe((result: SearchResultsExtended<Option>) => {
          this.getOptionsRecursive(result)
        })
    )
  }
  private getOptionsRecursive(result: SearchResultsExtended<Option>) {
    this.packOptions = [...this.packOptions, ...result.items.filter((item) => item.ref)]
    // this.updateRequireOptions();
    if (result.batching && result.batching.next) {
      this.subs.push(
        this.grange.core.api
          .get(result.batching.next)
          .subscribe((next: SearchResultsExtended<Option>) => {
            this.getOptionsRecursive(next)
          })
      )
    }
  }

  private getGroupOptions() {
    let path: string
    if (this.model && this.model['@id']) {
      path = this.model['@id']
    } else if (this.path) {
      path = this.path
    }
    if (!path) {
      return
    }
    path = this.normalizer.normalize(path).split('/').slice(0, 3).join('/')
    this.grange.core.resource
      .find({ portal_type: 'abb.orders.maingroup', path, sort_on: 'getId' })
      .subscribe((result: SearchResults) => {
        this.getGroupOptionsRecursive(result)
      })
  }
  private getGroupOptionsRecursive(result: SearchResults) {
    this.packGroupOptions = [...this.packGroupOptions, ...result.items]
    if (result.batching && result.batching.next) {
      this.subs.push(
        this.grange.core.api.get(result.batching.next).subscribe((next: SearchResults) => {
          this.getGroupOptionsRecursive(next)
        })
      )
    }
  }
  public setFile($event) {
    if ($event.target.files && $event.target.files.length) {
      let newAttachment: File
      newAttachment = $event.target.files[0]
      const reader = new FileReader()
      reader.readAsDataURL(newAttachment)
      reader.onload = () => {
        if (typeof reader.result === 'string') {
          this.model.fitxer = {
            'content-type': newAttachment.type,
            filename: newAttachment.name,
            data: reader.result.split(',')[1],
            encoding: reader.result.split(';')[1].split(',')[0],
          }
        }
      }
    } else {
      this.model.fitxer = undefined
    }
  }

  public updateAmountForm() {
    if (!this.amountForm) {
      return
    }
    const value: any = Object.assign({}, this.amountForm.value)
    if (this.model.checkbox) {
      value.type = this.model.readonly ? 'checkboxPreselected' : 'checkbox'
    } else {
      value.type = !!this.model.amount ? 'definedQty' : 'undefinedQty'
    }
    if (this.model.amount) {
      value.definedQtyAmount = this.model.amount
    }
    setTimeout(() => {
      this.amountForm.reset(value)
    })
  }

  public updateLogicForm() {
    if (this.logicForm) {
      const value: any = {}
      const newRefs = []
      if (this.model.logic) {
        this.model.logic.split(',').map((option: string) => {
          if (option.startsWith('needs')) {
            const amount = option.split(' ')[1]
            const ref = option.split(' ')[2]
            if (!value.require) {
              value.require = {}
            }
            newRefs.push(ref)
            value.require[ref] = amount
          }
          if (option.startsWith('no')) {
            if (!value.exclude) {
              value.exclude = []
            }
            const ref = option.split(' ')[1]
            value.exclude.push(ref)
          }
          if (option.startsWith('price')) {
            const percent = option.split(' - ')[2].replace('%', '')
            const title = option.split(' - ')[1]
            if (!value.require_percent) {
              value.require_percent = { options: [], amount: percent }
            }
            value.require_percent.options.push(title)
          }
        })
      }
      this.requireOptions = newRefs
      setTimeout(() => {
        this.logicForm.reset(value)
      })
    }
  }

  getTitle(ref: string) {
    const find = this.packOptions.find((optin) => optin.ref === ref)
    return !!find ? find.title : ''
  }
}
