import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'
import { Grange } from 'grange'
import { User } from 'app/views/users/interfaces'
import { Subscription } from 'rxjs'
import { Group } from '../interfaces'

@Component({
  selector: 'app-group-users',
  templateUrl: './group-users.component.html',
  styleUrls: ['./group-users.component.scss'],
})
export class GroupUsersComponent implements OnInit {
  @Input() group: Group
  @Output() cancel: EventEmitter<Group> = new EventEmitter<Group>()
  @Output() save: EventEmitter<Group> = new EventEmitter<Group>()

  public step: 'initial' | 'confirm' = 'initial'

  public isLoadingData: boolean = false
  public users: Array<User> = []
  private searchVal: string
  public get search(): string {
    return this.searchVal
  }
  public set search(val: string) {
    this.searchVal = val
    this.updateUsers()
  }

  private groupUsers: Array<string> = []
  private savedUsers: { [username: string]: boolean } = {}
  private usersSub: Subscription
  private usersTimer: any
  public error: string

  constructor(private grange: Grange) {}

  ngOnInit() {
    this.isLoadingData = true
    this.updateUsers()
    this.loadGroupUsers()
  }

  private loadGroupUsers() {
    this.grange.core.resource
      .get(this.group['@id'])
      .subscribe((group: { users: { items: Array<string> } }) => {
        if (!!group.users && !!group.users.items) {
          this.groupUsers = group.users.items
        }
      })
  }

  private updateUsers() {
    clearTimeout(this.usersTimer)
    this.usersTimer = setTimeout(() => {
      if (this.usersSub) {
        this.usersSub.unsubscribe()
      }
      this.usersSub = this.grange.core.resource
        .users(this.search)
        .subscribe((users: Array<User>) => {
          this.users = users
          this.isLoadingData = false
        })
    }, 300)
  }

  public userChecked(user: User): boolean {
    if (!!this.savedUsers && this.savedUsers[user.username]) {
      return this.savedUsers[user.username]
    }
    return !!this.groupUsers && this.groupUsers.includes(user.username)
  }

  public checkUser(check: boolean, user: User) {
    this.savedUsers[user.username] = check
  }

  onCancel() {
    this.cancel.emit(this.group)
  }

  onConfirm() {
    const result = Object.assign(this.group, { users: this.savedUsers })
    this.grange.core.resource.updateGroup(result).subscribe(
      (group) => {
        this.group = group
        this.save.emit(this.group)
      },
      (error) => {
        if (!!error && !!error.message) {
          this.error = error.message
        }
      },
      () => {}
    )
  }

  getSelectedUsersToConfirm() {
    let result = []
    Object.keys(this.savedUsers).forEach((key) => {
      if (this.savedUsers[key]) {
        result.push(this.users.find((user) => user.username === key))
      }
    })
    this.groupUsers.forEach((username) => {
      if (!(username in this.savedUsers) || this.savedUsers[username]) {
        result.push(this.users.find((user) => user.username === username))
      }
    })
    return result
  }

  onSave() {
    this.step = 'confirm'
  }

  previousStep() {
    this.step = 'initial'
  }
}
