import { Component, OnInit, Input } from '@angular/core'
import { Group } from 'app/views/groups/interfaces'
import { BaseComponent } from 'app/components/base'
import { Grange } from 'grange'
import { SharingResponse } from '@guillotinaweb/grange-core'
import { MatDialog } from '@angular/material/dialog'
import { ManageProjectAccessDialogComponent } from './manage-project-access-dialog/manage-project-access-dialog.component'
import { Sort } from '@angular/material/sort'
import { Normalizer } from 'angular-traversal'

@Component({
  selector: 'app-manage-project-access',
  templateUrl: './manage-project-access.component.html',
  styleUrls: ['./manage-project-access.component.scss'],
})
export class ManageProjectAccessComponent extends BaseComponent implements OnInit {
  protected EXLUDE_GROUPS = ['Reviewers', 'Site Administrators', 'AuthenticatedUsers']
  protected LIST_ROLES = ['ABB', 'Linebuilder', 'Client']
  protected searchVal: string
  @Input() public get search(): string {
    return this.searchVal
  }
  public set search(val: string) {
    this.searchVal = val
    this.setData()
  }
  public data: Array<Group> = []
  public path: string
  private sharingVal: SharingResponse
  public get sharing(): SharingResponse {
    return this.sharingVal
  }
  public set sharing(val: SharingResponse) {
    this.sharingVal = val
    this.setData()
  }
  private groupsVal: Array<Group> = []
  public get groups(): Array<Group> {
    return this.groupsVal
  }
  public set groups(val: Array<Group>) {
    this.groupsVal = val
    this.setData()
  }

  public sort: Sort = {
    active: 'title',
    direction: 'desc',
  }
  protected sortDataVal: Array<Group> = []
  public get sortData(): Array<Group> {
    return this.sortDataVal
  }
  public set sortData(val: Array<Group>) {
    this.sortDataVal = val
  }

  constructor(public grange: Grange, public dialog: MatDialog, private normalizer: Normalizer) {
    super(grange)
  }

  public sortChange(sort: Sort) {
    this.sort = sort
    const data = this.data.slice()
    if (!sort.active || sort.direction === '') {
      this.sortData = data
      return
    }
    this.data = data.sort((a: Group, b: Group) => {
      const isAsc = sort.direction === 'asc'
      if (!a[sort.active]) {
        return -1 * (isAsc ? 1 : -1)
      }
      if (!b[sort.active]) {
        return 1 * (isAsc ? 1 : -1)
      }
      return (
        (a[sort.active].toLowerCase() < b[sort.active].toLowerCase() ? -1 : 1) * (isAsc ? -1 : 1)
      )
    })
  }
  ngOnInit(): void {
    this.loadGroups()
    this.contextPath.subscribe((path: string) => {
      this.path = path
      this.loadSharing()
    })
    this.sortChange(this.sort)
  }
  private loadSharing() {
    this.grange.core.resource.sharing(this.path, this.search).subscribe((result) => {
      this.sharing = result
    })
  }

  public sendMail(group) {
    const payload = {
      url: window.location.href.split('@@')[0],
      group: group.id,
    }
    this.grange.core.api.post(this.path + '/@sendReset', payload).subscribe((res) => {
      group.send = 'done'
    })
  }

  private loadGroups() {
    this.grange.core.resource.groups(this.search).subscribe((result) => {
      this.groups = result.filter((group: Group) => !this.EXLUDE_GROUPS.includes(group.id))
    })
  }

  public setData() {
    this.data = this.groups
      .filter(
        (searchGroup) =>
          !this.search || searchGroup.title.toLowerCase().includes(this.search.toLowerCase())
      )
      .filter(
        (groupInSharing) =>
          !this.sharing ||
          this.sharing.entries
            .filter(
              (entrie) =>
                entrie.type === 'group' && this.LIST_ROLES.some((role) => entrie.roles[role])
            )
            .map((entrie) => entrie.id)
            .includes(groupInSharing.id)
      )
    this.sortChange(this.sort)
  }
  private getRoles(group: Group): { [role: string]: boolean } {
    if (!group.description) {
      return {}
    }
    try {
      return JSON.parse(group.description)
    } catch (e) {
      return {}
    }
  }

  public deleteGroup(group: Group) {
    const roles = {}
    this.LIST_ROLES.map((role) => (roles[role] = false))
    const updateSharing = {
      inherit: this.sharing.inherit,
      entries: [
        {
          type: 'group',
          id: group.id,
          roles,
        },
      ],
    }
    this.grange.core.resource.updateSharing(this.path, updateSharing).subscribe((sharing) => {
      this.sharing = Object.assign(this.sharing, {
        entries: this.sharing.entries.filter(
          (entrie) => !(entrie.type === 'group' && entrie.id === group.id)
        ),
      })
    })
  }

  public openAddDialog() {
    const data = {
      groups: this.groups,
      sharing: this.sharing,
      client: this.normalizer.normalize(this.path).split('/')[1],
    }
    const dialog = this.dialog.open(ManageProjectAccessDialogComponent, {
      data,
      minWidth: 500,
      maxHeight: '80vh',
    })
    dialog.afterClosed().subscribe((result: { ok: boolean; added: Array<Group> }) => {
      if (!result || !result.ok) {
        return
      }
      const updateSharing = { inherit: this.sharing.inherit, entries: [] }
      updateSharing.entries = result.added.map((result) => {
        return {
          type: 'group',
          id: result.id,
          roles: this.getRoles(result),
        }
      })
      this.grange.core.resource.updateSharing(this.path, updateSharing).subscribe((result) => {
        this.sharing = Object.assign(this.sharing, {
          entries: [...this.sharing.entries, ...updateSharing.entries],
        })
      })
    })
  }
}
