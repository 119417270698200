import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core'
import { BaseView, Grange } from 'grange'
import { Subscription } from 'rxjs'
import {
  DeleteDialogData,
  DeleteDialogComponent,
} from 'app/shared/dialogs/delete-dialog/delete-dialog.component'
import { Group } from './interfaces'
import { MatDialog } from '@angular/material/dialog'
import { GroupsDialogComponent, GroupDialogData } from './groups-dialog/groups-dialog.component'
import {
  GroupUsersDialogData,
  GroupsDialogUsersComponent,
} from './groups-dialog-users/groups-dialog-users.component'
import {
  GroupSharingDialogData,
  GroupsDialogSharingComponent,
} from './groups-dialog-sharing/groups-dialog-sharing.component'
import { BaseItem, SearchResults } from '@guillotinaweb/grange-core'

@Component({
  selector: 'app-groups',
  templateUrl: './groups.component.html',
  styleUrls: ['./groups.component.scss'],
})
export class GroupsComponent extends BaseView implements OnInit {
  protected EXLUDE_GROUPS = ['Reviewers', 'Site Administrators', 'AuthenticatedUsers']
  private searchVal: string
  public get search(): string {
    return this.searchVal
  }
  public set search(val: string) {
    this.searchVal = val
    this.updateGroups()
  }
  public groups: Array<Group> = []
  public projects: Array<BaseItem | any> = []
  public mapProjectSharing = {}

  private groupsSub: Subscription
  private groupsTimer: any
  public isLoadingData: boolean = false
  constructor(public grange: Grange, public dialog: MatDialog) {
    super(grange)
  }

  ngOnInit() {
    this.isLoadingData = true
    const query = {
      portal_type: 'abb.orders.project',
    }
    this.grange.core.resource.find(query, '/', {}).subscribe((result: SearchResults) => {
      this.projects = []
      this.recursiveSearch(result)
    })
  }

  private recursiveSearch(result: SearchResults) {
    this.projects = [...this.projects, ...result.items]
    if (result.batching && result.batching.next) {
      this.grange.core.resource.get(result.batching.next).subscribe((newResult: SearchResults) => {
        this.recursiveSearch(newResult)
      })
    } else {
      this.projects.forEach((project) => {
        this.grange.core.resource.get(`${project['@id']}/@sharing`).subscribe((projectSharing) => {
          this.mapProjectSharing[project['@id']] = projectSharing
        })
      })
      this.updateGroups()
    }
  }

  updateGroups() {
    clearTimeout(this.groupsTimer)
    this.groupsTimer = setTimeout(() => {
      if (this.groupsSub) {
        this.groupsSub.unsubscribe()
      }
      this.groupsSub = this.grange.core.resource.groups(this.search).subscribe((groups) => {
        this.groups = groups
          .filter((group: Group) => {
            return !this.EXLUDE_GROUPS.includes(group.id)
          })
          .sort((a: Group, b: Group) => {
            if (a.title.toLowerCase() < b.title.toLowerCase()) {
              return -1
            } else {
              return 1
            }
          })
        this.isLoadingData = false
      })
    }, 300)
  }

  getProjectByGroup(group: Group) {
    const result = []
    this.projects.forEach((project) => {
      const sharing = this.mapProjectSharing[project['@id']]
      const find = sharing.entries.find((entry) => entry.id === group.id)
      if (find) {
        result.push({
          link: `${project['@id']}/@@manage-project`,
          title: project.title,
        })
      }
    })
    return result
  }

  trackByProjectList(index, project) {
    // Es necessari, ja que sino angular torna a fer un render a l'iterar el bucle i el clic de l'anchor en primera intància no funciona
    return project.id
  }

  openDialog(group?: Group) {
    const data: GroupDialogData = {
      title: 'Add Group',
      group: {
        title: '',
        id: '',
        roles: [],
        users: [],
        groupname: '',
      },
      mode: 'add',
    }
    if (group) {
      data.title = `Edit ${group.title} (${group.groupname})`
      data.group = Object.assign(data.group, group)
      data.mode = 'edit'
    }
    const dialog = this.dialog.open(GroupsDialogComponent, { data, minWidth: 500 })
    dialog.afterClosed().subscribe(() => {
      this.updateGroups()
    })
  }

  openDialogGroupUsers(group?: Group) {
    const data: GroupUsersDialogData = {
      group,
    }
    const dialog = this.dialog.open(GroupsDialogUsersComponent, { data, minWidth: 500 })
    dialog.afterClosed().subscribe(() => {
      this.updateGroups()
    })
  }

  openDialogGroupSharing(group?: Group) {
    const data: GroupSharingDialogData = {
      group,
    }
    const dialog = this.dialog.open(GroupsDialogSharingComponent, { data, minWidth: 500 })
    dialog.afterClosed().subscribe(() => {
      // this.updateGroups();
    })
  }

  getRolesInfo(group?: Group) {
    const description = JSON.parse(group.description)
    const result = []
    if (description && 'ABB' in description && description['ABB']) {
      result.push('ABB')
    }
    if (description && 'Linebuilder' in description && description['Linebuilder']) {
      result.push('Linebuilder')
    }
    if (description && 'Client' in description && description['Client']) {
      result.push('Client')
    }
    return result.join(', ')
  }

  openDialogDelete(group?: Group) {
    const data: DeleteDialogData = {
      title: group.title,
    }
    const dialog = this.dialog.open(DeleteDialogComponent, { data })
    dialog.afterClosed().subscribe((del: boolean) => {
      if (!del) {
        return
      }
      this.grange.core.resource.deleteGroup(group).subscribe(() => {
        this.updateGroups()
      })
    })
  }
  loadUsers(group: Group) {
    if (!group.users) {
      this.grange.core.resource
        .get(group['@id'])
        .subscribe((loadedGroup) => (group = Object.assign(group, loadedGroup)))
    }
  }
}
