import { Component, OnInit, ChangeDetectorRef, Input } from '@angular/core'
import { Grange } from 'grange'
import { Location } from '@angular/common'
import { User } from 'app/views/users/interfaces'
import { BaseComponent } from '../base'
import { Normalizer } from 'angular-traversal'
import { Menu } from 'app/theme/components/menu/menu.model'
import { switchMap, take, tap } from 'rxjs/operators'

@Component({
  selector: 'app-site',
  templateUrl: './site.component.html',
  styleUrls: ['./site.component.scss'],
})
export class SiteComponent extends BaseComponent implements OnInit {
  public isAuthenticated = true
  public user: User
  public menu = []
  public menuHierarchy = []
  private projectPath = ''
  @Input() public wideContent

  constructor(
    public grange: Grange,
    private normalizer: Normalizer,
    private cdr: ChangeDetectorRef,
    private readonly location: Location
  ) {
    super(grange)
  }

  ngOnInit() {
    this.contextPath
      .pipe(
        take(1),
        tap((path) => {
          if (path.split('/').length >= 3) {
            this.projectPath = this.normalizer.normalize(path).split('/').slice(0, 3).join('/')
          }
        }),
        switchMap(() => this.grange.core.auth.getUser()),
        tap((user) => {
          this.user = user
          this.isAuthenticated = true
          this.cdr.markForCheck()
        }),
        switchMap(() => this.context)
      )
      .subscribe(
        (context) => {
          setTimeout(() => {
            if (
              (context['@type'] === 'abb.orders.project' && context.review_state === 'closed') ||
              context.parent.review_state === 'closed'
            ) {
              this.menu = [
                new Menu(
                  1,
                  'SEE ALL ORDERS',
                  `${this.projectPath}/@@pre-order`,
                  null,
                  'search',
                  null,
                  false,
                  0,
                  true
                ),
                new Menu(
                  4,
                  'SEE ALL X-LIST TEMPLATES',
                  `${this.projectPath}/@@all-templates`,
                  null,
                  'search',
                  null,
                  false,
                  0,
                  true
                ),
                new Menu(
                  5,
                  'DETAIL REPORT ORDERS',
                  `${this.projectPath}/@@reportLBxlsx_form`,
                  null,
                  'archive',
                  null,
                  false,
                  0,
                  true
                ),
                new Menu(
                  8,
                  'SEE CHANGE REQUESTS',
                  `${this.projectPath}/@@all-change-requests`,
                  null,
                  'search',
                  null,
                  false,
                  0,
                  true
                ),
                new Menu(
                  9,
                  'DETAIL REPORT CR',
                  `${this.projectPath}/@@exportCRxlsx`,
                  null,
                  'archive',
                  null,
                  false,
                  0,
                  true
                ),
                new Menu(
                  10,
                  'USER MANUAL',
                  null,
                  'https://xlists-back.abb.iskra.cat/usermanual.pdf',
                  'info',
                  null,
                  false,
                  0,
                  true
                ),
              ]
            } else {
              this.menu = [
                new Menu(
                  1,
                  'SEE ALL ORDERS',
                  `${this.projectPath}/@@pre-order`,
                  null,
                  'search',
                  null,
                  false,
                  0,
                  true
                ),
                new Menu(
                  2,
                  'ORDERS TO BE REVIEWED',
                  `${this.projectPath}/@@orders-review`,
                  null,
                  'all_inbox',
                  null,
                  false,
                  0,
                  true
                ),
                new Menu(
                  3,
                  'CREATE AN ORDER',
                  `${this.projectPath}/@@addOrder`,
                  null,
                  'add',
                  null,
                  false,
                  0,
                  true
                ),
                new Menu(
                  4,
                  'SEE ALL X-LIST TEMPLATES',
                  `${this.projectPath}/@@all-templates`,
                  null,
                  'search',
                  null,
                  false,
                  0,
                  true
                ),
                new Menu(
                  5,
                  'DETAIL REPORT ORDERS',
                  `${this.projectPath}/@@reportLBxlsx_form`,
                  null,
                  'archive',
                  null,
                  false,
                  0,
                  true
                ),
                new Menu(
                  7,
                  'CREATE CHANGE REQUEST',
                  `${this.projectPath}/@@addChangeRequest`,
                  null,
                  'add',
                  null,
                  false,
                  0,
                  true
                ),
                new Menu(
                  8,
                  'SEE CHANGE REQUESTS',
                  `${this.projectPath}/@@all-change-requests`,
                  null,
                  'search',
                  null,
                  false,
                  0,
                  true
                ),
                new Menu(
                  9,
                  'DETAIL REPORT CR',
                  `${this.projectPath}/@@exportCRxlsx`,
                  null,
                  'archive',
                  null,
                  false,
                  0,
                  true
                ),
                new Menu(
                  10,
                  'USER MANUAL',
                  null,
                  'https://xlists-back.abb.iskra.cat/usermanual.pdf',
                  'info',
                  null,
                  false,
                  0,
                  true
                ),
              ]
            }
          })
          if (
            this.user &&
            context['@type'] === 'Plone Site' &&
            this.location.path().indexOf('welcome') === -1 &&
            this.location.path().indexOf('admin') === -1 &&
            this.location.path().indexOf('logout') === -1
          ) {
            this.grange.traverser.traverse('./@@welcome')
          }
        },
        (err) => {
          this.isAuthenticated = false
          if (err.status === 401) {
            this.grange.traverser.traverse('./@@login')
          }
        }
      )

    this.menuHierarchy = [
      { parentMenuItem: 3, type: 'abb.orders.order', view: 'addOrderStep2' },
      { parentMenuItem: 7, type: 'abb.orders.changerequest', view: 'addChangeRequestStep2' },
      { parentMenuItem: 8, type: 'abb.orders.changerequest', view: '*' },
      { parentMenuItem: 1, type: 'abb.orders.order', view: '*' },
      { parentMenuItem: 4, type: 'abb.orders.project', view: 'templates' },
    ]
  }
}
