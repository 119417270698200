import { Component, OnInit } from '@angular/core'
import { Grange, BaseViewModel } from 'grange'
import { OrderResource, ViewOrder } from 'app/models/order.model'
import { ConfirmDialogComponent } from 'app/shared/dialogs/confirm-dialog/confirm-dialog.component'
import { MatDialog } from '@angular/material/dialog'
import { DeleteDialogComponent } from 'app/shared/dialogs/delete-dialog/delete-dialog.component'
import { User } from '../users/interfaces'
import { take } from 'rxjs/operators'
import { Project } from 'app/models/project.model'

@Component({
  selector: 'app-view',
  templateUrl: './view-order.component.html',
  styleUrls: ['./view-order.component.scss'],
})
export class ViewOrderComponent extends BaseViewModel<OrderResource> implements OnInit {
  order: OrderResource
  path: string
  pathParent: string
  user: User
  canDelete = false
  canEdit = false
  viewOrder: ViewOrder
  project: Project

  constructor(public grange: Grange, public dialog: MatDialog) {
    super(grange)
  }

  ngOnInit() {
    this.context.subscribe((context) => {
      this.order = context

      this.grange.core.api
        .get(this.order.parent['@id'])
        .pipe(take(1))
        .subscribe((result) => {
          this.project = result
        })

      this.computeCanDelete()
      this.computeCanEdit()
    })
    this.contextPath.pipe(take(1)).subscribe((path) => {
      this.path = path
      this.grange.core.api
        .get(`${path}/@viewOrder`)
        .pipe(take(1))
        .subscribe((result) => {
          this.viewOrder = result
        })
    })
    this.parentPath.subscribe((pathParent) => (this.pathParent = pathParent))
    this.grange.core.auth.getUser().subscribe((user) => {
      this.user = user
    })
  }

  public computeCanEdit() {
    this.grange.core.api
      .get(this.order['@id'] + '/@cani/Modify portal content')
      .subscribe((permission) => {
        this.canEdit = permission['perm'] === 1
      })
  }

  public computeCanDelete() {
    this.grange.core.api
      .get(this.order['@id'] + '/@cani/Delete objects')
      .subscribe((permission) => {
        if (
          this.order.review_state === 'order-accepted' ||
          this.order.review_state === 'draft-pre-order' ||
          this.order.review_state === 'sent-to-abb'
        ) {
          if (
            this.order.linebuilder === this.grange.core.auth.getUsername() &&
            permission['perm'] === 1
          ) {
            this.canDelete = true
          } else {
            this.canDelete = false
          }
          if (this.grange.core.auth.getUsername() === 'admin') {
            this.canDelete = true
          }
        } else {
          this.canDelete = permission['perm'] === 1
        }
      })
  }

  public copy() {
    const dialog = this.dialog.open(ConfirmDialogComponent, {
      data: { title: 'Are you sure you want to copy?' },
    })
    dialog.afterClosed().subscribe((res: boolean) => {
      if (!res) {
        return
      }
      this.grange.core.api
        .post(this.pathParent + '/@copy', {
          source: this.order.UID,
        })
        .subscribe((copied) => {
          this.grange.traverser.traverse(copied[0].target + '/@@edit')
        })
    })
  }

  public delete() {
    const dialog = this.dialog.open(DeleteDialogComponent, {
      data: { title: 'Are you sure want to delete the order?' },
    })
    dialog.afterClosed().subscribe((res: boolean) => {
      if (!res) {
        return
      }
      this.grange.core.resource.delete(this.order['@id']).subscribe(() => {
        this.grange.traverser.traverse(this.pathParent + '/@@pre-order')
      })
    })
  }

  public canDuplicateOrder() {
    return (
      this.viewOrder &&
      this.project &&
      this.viewOrder.pack.review_state !== 'rejected' &&
      this.project.enable_templates.some(
        (template) => template['@id'] === this.viewOrder.pack['@id']
      )
    )
  }
}
