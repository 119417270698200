import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'
import { GrangeImage, GrangeFile } from '@guillotinaweb/grange-core'
import { Observable } from 'rxjs'
import { Grange } from 'grange'
import { HttpHeaders, HttpClient, HttpEvent } from '@angular/common/http'

@Component({
  selector: 'app-image-selector',
  templateUrl: './image-selector.component.html',
  styleUrls: ['./image-selector.component.scss'],
})
export class ImageSelectorComponent implements OnInit {
  @Input() readonly = false
  @Input() public image: GrangeImage
  @Output() public imageChange: EventEmitter<GrangeImage> = new EventEmitter<GrangeImage>()
  public get attachment(): string {
    return this.image ? this.image.filename : ''
  }

  constructor() {}

  ngOnInit(): void {}

  public setFile($event) {
    if ($event.target.files && $event.target.files.length) {
      let newAttachment: File
      newAttachment = $event.target.files[0]
      const reader = new FileReader()
      reader.readAsDataURL(newAttachment)
      reader.onload = () => {
        if (typeof reader.result === 'string') {
          this.image = {
            'content-type': newAttachment.type,
            filename: newAttachment.name,
            data: reader.result.split(',')[1],
            encoding: reader.result.split(';')[1].split(',')[0],
          }
          this.imageChange.emit(this.image)
        }
      }
    }
  }
  public deleteFile() {
    this.image = null
    this.imageChange.emit(this.image)
  }
}
