import { Component, OnInit, OnDestroy } from '@angular/core'
import { Grange, BaseView } from 'grange'
import { TraverserSelectors } from '@guillotinaweb/ngx-state-traverser'
import { takeUntil } from 'rxjs/operators'
import { Pack } from 'app/models/pack.model'
import { Observable, Subscriber, Subscription } from 'rxjs'
import { Resource } from '@guillotinaweb/grange-core'
import { HttpParams } from '@angular/common/http'

@Component({
  selector: 'app-view-pack-project',
  templateUrl: './view-pack-project.component.html',
  styleUrls: ['./view-pack-project.component.scss'],
})
export class TemplateProjectComponent extends BaseView implements OnInit, OnDestroy {
  public path: string
  public templateId: string

  context: Observable<Resource> = undefined
  real_context: Resource
  excelDownload: string
  excelTitle: string
  sub: Subscription

  constructor(public grange: Grange) {
    super(grange)
  }

  ngOnInit() {
    this.contextPath.subscribe((path) => {
      this.path = path
      this.sub = this.grange.traverser.target.subscribe((res) => {
        if (res.query) {
          this.templateId = res.query.get('ident')
          if (this.templateId) {
            this.grange.core.api
              .get(path + '/@getPack?id=' + this.templateId)
              .subscribe((context) => (this.real_context = context))
          }
        }
      })
    })
    const httpParams = new HttpParams({ fromString: window.location.href.split('?')[1] || '' })
    if (httpParams.get('ident')) {
      this.excelDownload = `${this.grange.core.api.getFullPath(
        this.path
      )}/@exportPack?ident=${httpParams.get('ident')}`
      this.excelTitle = `${httpParams.get('ident')}.xlsx`
    }
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe()
    }
  }
}
