import { Component, OnInit, Inject } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { ProjectResource } from 'app/models/project.model'
import { BaseItem } from '@guillotinaweb/grange-core'
import { Sort } from '@angular/material/sort'

interface PacksControl {
  selected: boolean
  pack: BaseItem
  disabled: boolean
}
@Component({
  selector: 'app-manage-project-xlists-dialog',
  templateUrl: './manage-project-xlists-dialog.component.html',
  styleUrls: ['./manage-project-xlists-dialog.component.scss'],
})
export class ManageProjectXlistsDialogComponent implements OnInit {
  public list: Array<PacksControl>

  protected searchVal = ''
  public get search(): string {
    return this.searchVal
  }
  public set search(val: string) {
    this.searchVal = val
    this.setData()
  }

  public filtredList: Array<PacksControl>
  public sortData: Array<PacksControl>

  public sort: Sort = {
    active: 'Title',
    direction: 'asc',
  }

  constructor(
    public dialogRef: MatDialogRef<ManageProjectXlistsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { project: ProjectResource; packs: Array<BaseItem> }
  ) {
    this.list = data.packs.map((pack) => {
      if (data.project.enable_templates === null) {
        data.project.enable_templates = []
      }
      const isSelected = data.project.enable_templates.some(
        (projectPack) => projectPack['@id'] === pack['@id']
      )
      return {
        selected: isSelected,
        pack,
        disabled: isSelected,
      }
    })
    this.setData()
  }

  ngOnInit(): void {}

  public sortChange(sort: Sort) {
    this.sort = sort
    const data = this.filtredList.slice()
    if (!sort.active || sort.direction === '') {
      this.sortData = data
      return
    }
    this.sortData = data.sort((a: PacksControl, b: PacksControl) => {
      const isAsc = sort.direction === 'asc'
      return (a.pack.title < b.pack.title ? -1 : 1) * (isAsc ? 1 : -1)
    })
  }

  public setData() {
    this.filtredList = this.list.filter((item) =>
      item.pack.title.toLowerCase().includes(this.search.toLowerCase())
    )
    this.sortChange(this.sort)
  }

  public close(ok: boolean) {
    const added = this.list
      .filter((item) => item.selected && !item.disabled)
      .map((item) => item.pack)
    this.dialogRef.close({ ok, added })
  }
}
