import { Component, OnInit } from '@angular/core'
import { BaseViewModel, Grange } from 'grange'
import { OrderResource, ViewOrder } from 'app/models/order.model'
import { take } from 'rxjs/operators'

@Component({
  selector: 'app-edit-order',
  templateUrl: './edit-order.component.html',
  styleUrls: ['./edit-order.component.scss'],
})
export class EditOrderComponent extends BaseViewModel<OrderResource> implements OnInit {
  order: OrderResource
  viewOrder: ViewOrder
  path: string

  constructor(public grange: Grange) {
    super(grange)
  }

  ngOnInit() {
    this.context.subscribe((context) => {
      this.order = context
    })
    this.contextPath.pipe(take(1)).subscribe((path) => {
      this.path = path
      this.grange.core.api
        .get(`${path}/@viewOrder`)
        .pipe(take(1))
        .subscribe((result) => {
          this.viewOrder = result
        })
    })
  }

  onFinish() {
    this.grange.traverser.traverse('.')
  }
}
