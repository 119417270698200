import { Component, OnInit } from '@angular/core'
import { Grange, BaseView } from 'grange'
import { Subscription } from 'rxjs'
import { MatDialog } from '@angular/material/dialog'
import { UserDialogComponent, UserDialogData } from './user-dialog/user-dialog.component'
import { User } from './interfaces'
import {
  DeleteDialogComponent,
  DeleteDialogData,
} from 'app/shared/dialogs/delete-dialog/delete-dialog.component'

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
})
export class UsersComponent extends BaseView implements OnInit {
  private searchVal: string
  public get search(): string {
    return this.searchVal
  }
  public set search(val: string) {
    this.searchVal = val
    this.updateUsers()
  }
  public users: Array<User> = []

  private usersSub: Subscription
  private usersTimer: any

  constructor(public grange: Grange, public dialog: MatDialog) {
    super(grange)
  }

  ngOnInit() {
    this.updateUsers()
  }

  updateUsers() {
    clearTimeout(this.usersTimer)
    this.usersTimer = setTimeout(() => {
      if (this.usersSub) {
        this.usersSub.unsubscribe()
      }
      this.usersSub = this.grange.core.resource.users(this.search).subscribe((users) => {
        this.users = users
      })
    }, 300)
  }

  openDialog(user?: User) {
    const data: UserDialogData = {
      title: 'Add User',
      user: {
        email: '',
        fullname: '',
        id: '',
        roles: [],
        username: '',
      },
      mode: 'add',
    }
    if (user) {
      data.title = `Edit ${user.fullname}`
      data.user = Object.assign(data.user, user)
      data.mode = 'edit'
    }
    const dialog = this.dialog.open(UserDialogComponent, { data, minWidth: 500 })
    dialog.afterClosed().subscribe(() => {
      this.updateUsers()
    })
  }

  openDialogDelete(user?: User) {
    const data: DeleteDialogData = {
      title: user.fullname,
    }
    const dialog = this.dialog.open(DeleteDialogComponent, { data })
    dialog.afterClosed().subscribe((del: boolean) => {
      if (!del) {
        return
      }
      this.grange.core.resource.deleteUser(user).subscribe(() => {
        this.updateUsers()
      })
    })
  }
  loadGroups(user: User) {
    if (!user.groups) {
      this.grange.core.resource
        .get(user['@id'].replace('@users', '@usersgroups'))
        .subscribe((loadedGroup) => (user = Object.assign(user, loadedGroup)))
    }
  }
}
