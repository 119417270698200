import { Pipe, PipeTransform } from '@angular/core'
import { Group } from 'app/views/groups/interfaces'

@Pipe({
  name: 'groupDefaultRoles',
})
export class GroupDefaultRolesPipe implements PipeTransform {
  transform(group: Group): string {
    if (!group.description) {
      return ''
    }
    try {
      const defaultRoles = JSON.parse(group.description)
      return Object.keys(defaultRoles)
        .filter((role) => role !== 'clientsSelected' && defaultRoles[role])
        .join(', ')
    } catch (e) {
      return ''
    }
  }
}
