import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core'
import { BaseView, Grange } from 'grange'
import { User } from '../users/interfaces'
import { markForCheck } from '@guillotinaweb/pastanaga-angular'

@Component({
  selector: 'app-welcome-admin',
  templateUrl: './welcome-admin.component.html',
  styleUrls: ['./welcome-admin.component.scss'],
})
export class WelcomeAdminComponent extends BaseView implements OnInit {
  public isAdmin = false
  public user: User

  constructor(public grange: Grange, private cdr: ChangeDetectorRef) {
    super(grange)
  }

  ngOnInit() {
    this.grange.core.auth.getUser().subscribe((user) => {
      this.user = user
      this.isAdmin = !!this.user && !!this.user.roles && this.user.roles.includes('Manager')
      markForCheck(this.cdr)
    })
  }
}
