import { Component, OnInit } from '@angular/core'
import { Client } from 'app/models/client.model'
import { MatDialogRef } from '@angular/material/dialog'

@Component({
  selector: 'app-clients-add-dialog',
  templateUrl: './clients-add-dialog.component.html',
  styleUrls: ['./clients-add-dialog.component.scss'],
})
export class ClientsAddDialogComponent implements OnInit {
  public model: Client = {
    '@id': null,
    '@type': 'abb.orders.client',
    file: null,
    description: null,
    review_state: null,
    title: null,
  }
  constructor(public dialogRef: MatDialogRef<ClientsAddDialogComponent>) {}

  ngOnInit(): void {}

  public done(ok: boolean) {
    this.dialogRef.close({ ok, model: this.model })
  }
}
