import { Component, OnInit } from '@angular/core'
import { Grange, BaseView } from 'grange'
import { HttpParams } from '@angular/common/http'

@Component({
  selector: 'app-view-pack',
  templateUrl: './view-pack.component.html',
  styleUrls: ['./view-pack.component.scss'],
})
export class ViewPackComponent extends BaseView implements OnInit {
  public path: string
  constructor(public grange: Grange) {
    super(grange)
  }

  ngOnInit() {
    this.contextPath.subscribe((path) => (this.path = path))
  }

  public exportPack() {
    const httpParams = new HttpParams({ fromString: window.location.href.split('?')[1] || '' })
    if (httpParams.get('ident')) {
      const url = `${this.grange.core.api.getFullPath(this.path)}/exportPack?ident=${httpParams.get(
        'ident'
      )}`
      window.open(url, '_blank')
    } else {
      alert('Error occured, contact administrator')
    }
  }
}
