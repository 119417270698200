import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit } from '@angular/core'
import { Grange, BaseViewModel } from 'grange'
import { ProjectResource } from 'app/models/project.model'
import { Subscription } from 'rxjs'
import { MatTabGroup } from '@angular/material/tabs'

@Component({
  selector: 'app-manage-project',
  templateUrl: './manage-project.component.html',
  styleUrls: ['./manage-project.component.scss'],
})
export class ManageProjectComponent
  extends BaseViewModel<ProjectResource>
  implements OnInit, OnDestroy, AfterViewInit
{
  project: ProjectResource
  path: string
  private subs: Array<Subscription> = []
  @ViewChild('matTabs') matTabs: MatTabGroup

  constructor(public grange: Grange) {
    super(grange)
  }

  ngOnInit() {
    const sub = this.context.subscribe((context) => {
      this.project = context
    })
    this.subs.push(sub)
  }
  ngAfterViewInit() {
    this.alignInkBar()
  }
  alignInkBar() {
    setTimeout(() => {
      this.matTabs._tabHeader._alignInkBarToSelectedTab()
    }, 1000)
  }
  ngOnDestroy() {
    this.subs.map((sub: Subscription) => {
      sub.unsubscribe()
    })
  }
}
