import { Component, OnInit } from '@angular/core'
import { BaseView, Grange, BaseViewModel } from 'grange'
import { ConfirmDialogComponent } from 'app/shared/dialogs/confirm-dialog/confirm-dialog.component'
import { DeleteDialogComponent } from 'app/shared/dialogs/delete-dialog/delete-dialog.component'
import { MatDialog } from '@angular/material/dialog'
import { ChangeRequestResource } from 'app/forms/change-request/change-request'
import { User } from '../users/interfaces'

@Component({
  selector: 'app-view-change-request',
  templateUrl: './view-change-request.component.html',
  styleUrls: ['./view-change-request.component.scss'],
})
export class ViewChangeRequestComponent
  extends BaseViewModel<ChangeRequestResource>
  implements OnInit
{
  protected states = [
    'linebuilder-draft',
    '1-1-submitted-to-the-client',
    'submitted-to-abb',
    'waiting-to-be-approved-by-client',
    'approved',
    'in-execution',
    'finalized',
  ]
  public changeRequest: ChangeRequestResource
  public path: string
  public pathParent: string
  public canDelete: boolean
  public canEdit: boolean
  // public get canEdit(): boolean {
  //   if (!this.user) {
  //     return false;
  //   }
  //   if (this.isAbb && ['submitted-to-abb', 'approved', 'in-execution', 'finalized'].includes(this.changeRequest.review_state)) {
  //     return true;
  //   }
  //   if (this.isClient && ['1-1-submitted-to-the-client', 'waiting-to-be-approved-by-client'].includes(this.changeRequest.review_state)) {
  //     return true;
  //   }
  //   if (!this.canViewPrices && this.changeRequest.review_state === 'linebuilder-draft') {
  //     return true;
  //   }
  //   if (this.user.roles.includes('Manager')) {
  //     return true;
  //   }
  //   return false;
  // }
  private user: User
  private isAbb: boolean
  private isClient: boolean
  private canViewPrices: boolean

  constructor(public grange: Grange, public dialog: MatDialog) {
    super(grange)
  }

  ngOnInit() {
    this.context.subscribe((context) => {
      this.changeRequest = context
      this.computeCanEdit()
      this.computeCanDelete()
    })
    this.contextPath.subscribe((path) => (this.path = path))
    this.parentPath.subscribe((pathParent) => (this.pathParent = pathParent))
    this.grange.core.auth.getUser().subscribe((user) => (this.user = user))
    this.grange.core.resource.get(this.path + '/@viewCR').subscribe((result) => {
      this.isAbb = result.isABB
      this.isClient = result.isClient
      this.canViewPrices = result.canViewPrices
    })
  }

  public computeCanEdit() {
    this.grange.core.api
      .get(this.changeRequest['@id'] + '/@cani/Modify portal content')
      .subscribe((permission) => {
        this.canEdit = permission['perm'] === 1
      })
  }

  public computeCanDelete() {
    this.grange.core.api
      .get(this.changeRequest['@id'] + '/@cani/Delete objects')
      .subscribe((permission) => {
        if (
          this.changeRequest.review_state === 'linebuilder-draft' ||
          this.changeRequest.review_state === '1-1-submitted-to-the-client' ||
          this.changeRequest.review_state === 'submitted-to-abb'
        ) {
          if (
            this.changeRequest.creator === this.grange.core.auth.getUsername() &&
            permission['perm'] === 1
          ) {
            this.canDelete = true
          } else {
            this.canDelete = false
          }
          if (this.grange.core.auth.getUsername() === 'admin') {
            this.canDelete = true
          }
        } else {
          this.canDelete = permission['perm'] === 1
        }
      })
  }

  public copy() {
    const dialog = this.dialog.open(ConfirmDialogComponent, {
      data: { title: 'Are you sure you want to copy?' },
    })
    dialog.afterClosed().subscribe((res: boolean) => {
      if (!res) {
        return
      }
      this.grange.core.api
        .post(this.pathParent + '/@copy', {
          source: this.changeRequest.UID,
        })
        .subscribe((copied) => {
          this.grange.traverser.traverse(copied[0].target + '/@@edit')
        })
      // const parent = this.order['@id'].split('/').slice(0, -1).join('/');
      // this.grange.core.resource.copy(this.changeRequest.UID, this.pathParent).subscribe((copied) => {
      //   this.grange.traverser.traverse(copied[0].target + '/@@edit');
      // });
    })
  }

  public delete() {
    const dialog = this.dialog.open(DeleteDialogComponent, {
      data: { title: 'Are you sure want to delete the change request?' },
    })
    dialog.afterClosed().subscribe((res: boolean) => {
      if (!res) {
        return
      }
      this.grange.core.resource.delete(this.changeRequest['@id']).subscribe(() => {
        this.grange.traverser.traverse(this.pathParent + '/@@all-change-requests')
      })
    })
  }
}
