import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-welcome-project',
  templateUrl: './welcome-project.component.html',
  styleUrls: ['./welcome-project.component.scss'],
})
export class WelcomeProjectComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
