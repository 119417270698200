import { Component, OnInit } from '@angular/core'
import { Client } from '../../models/client.model'
import { BaseFormsComponent } from '../base.forms.component'
import { Grange } from 'grange'

@Component({
  selector: 'app-client',
  templateUrl: './client.component.html',
  styleUrls: ['./client.component.scss'],
})
export class ClientComponent extends BaseFormsComponent<Client> implements OnInit {
  public get attachment(): string {
    return this.model.file ? this.model.file.filename : ''
  }

  constructor(public grange: Grange) {
    super(grange)
  }
  ngOnInit() {}

  public setFile($event) {
    if ($event.target.files && $event.target.files.length) {
      let newAttachment: File
      newAttachment = $event.target.files[0]
      const reader = new FileReader()
      reader.readAsDataURL(newAttachment)
      reader.onload = () => {
        if (typeof reader.result === 'string') {
          this.model.file = {
            'content-type': newAttachment.type,
            filename: newAttachment.name,
            data: reader.result.split(',')[1],
            encoding: reader.result.split(';')[1].split(',')[0],
          }
        }
      }
    } else {
      this.model.file = undefined
    }
  }
}
