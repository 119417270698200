import { Injectable } from '@angular/core'
import { Resolver } from 'angular-traversal'
import { Observable, of } from 'rxjs'
import { catchError, map } from 'rxjs/operators'
import { APIService, ResourceService, Error } from '@guillotinaweb/grange-core'
import { Grange } from 'grange'
import { LoginComponent } from './views/login/login.component'
import { AddComponent } from './views/add/add.component'
import { EditComponent } from './views/edit/edit.component'
import { ViewComponent } from './views/view/view.component'
import { LogoutComponent } from './views/logout/logout.component'
import { PreOrderComponent } from './views/pre-order/pre-order.component'
import { FolderComponent } from './views/folder/folder.component'
import { OrdersReviewComponent } from './views/orders-review/orders-review.component'
import { AllTemplatesComponent } from './views/all-templates/all-templates.component'
import { AllChangeRequestsComponent } from './views/all-change-requests/all-change-requests.component'
import { ReportLBxlsxFormComponent } from './views/report-lbxlsx-form/report-lbxlsx-form.component'
import { ReportTotalComponent } from './views/report-total/report-total.component'
import { ExportCRxlsxComponent } from './views/export-crxlsx/export-crxlsx.component'
import { UsersComponent } from './views/users/users.component'
import { GroupsComponent } from './views/groups/groups.component'
import { ClientsComponent } from './views/clients/clients.component'
import { WelcomeComponent } from './views/welcome/welcome.component'
import { WelcomeAdminComponent } from './views/welcome-admin/welcome-admin.component'
import { ManageClientComponent } from './views/manage-client/manage-client.component'
import { ManageProjectComponent } from './views/manage-project/manage-project.component'
import { AddAdminComponent } from './views/add-admin/add-admin.component'
import { ManagePackComponent } from './views/manage-pack/manage-pack.component'
import { WelcomeProjectComponent } from './views/welcome-project/welcome-project.component'
import { ViewOrderComponent } from './views/view-order/view-order.component'
import { EditAdminComponent } from './views/edit-admin/edit-admin.component'
import { AddOrderComponent } from './views/add-order/add-order.component'
import { AddChangeRequestComponent } from './views/add-change-request/add-change-request.component'
import { ViewChangeRequestComponent } from './views/view-change-request/view-change-request.component'
import { ViewPackComponent } from './views/view-pack/view-pack.component'
import { EditOrderComponent } from './views/edit-order/edit-order.component'
import { ResetComponent } from './views/reset/reset.component'
import { RealResetComponent } from './views/real-reset/real-reset.component'
import { ManageEmailsComponent } from './views/manage-emails/manage-emails.component'
import { TemplateProjectComponent } from './views/view-pack-project/view-pack-project.component'

@Injectable({
  providedIn: 'root',
})
export class RESTAPIResolver extends Resolver {
  constructor(private api: APIService, private resource: ResourceService) {
    super()
  }

  resolve(path: string, view: string, queryString: string): Observable<any> {
    if (path.startsWith('/passwordreset/')) {
      return of({ '@id': path, '@type': 'ResetPassword' })
    }
    return this.api.get(`${path}?metadata_fields=UID`).pipe(
      map((context: { '@id': string }) =>
        Object.assign(context, { '@id': context['@id'].replace('?metadata_fields=UID', '') })
      ),
      catchError((err: Error) => {
        if (!!err.response && err.response.status === 401) {
          this.resource.traversingUnauthorized.emit(path)
          return of({ path, isForbidden: true })
        } else {
          throw err
        }
      })
    )
  }
}

@Injectable({
  providedIn: 'root',
})
export class XListViews {
  constructor(private grange: Grange) {}

  initialize() {
    this.grange.traverser.addView('view', 'ResetPassword', RealResetComponent)
    this.grange.traverser.addView('login', '*', LoginComponent)
    this.grange.traverser.addView('reset', '*', ResetComponent)
    this.grange.traverser.addView('view', '*', ViewComponent)
    this.grange.traverser.addView('edit', '*', EditComponent)
    this.grange.traverser.addView('mails', 'Plone Site', ManageEmailsComponent)
    this.grange.traverser.addView('admin', 'Plone Site', WelcomeAdminComponent)
    this.grange.traverser.addView('users', 'Plone Site', UsersComponent)
    this.grange.traverser.addView('groups', 'Plone Site', GroupsComponent)
    this.grange.traverser.addView('clients', 'Plone Site', ClientsComponent)
    this.grange.traverser.addView('welcome', '*', WelcomeComponent)
    this.grange.traverser.addView('add', 'Plone Site', AddAdminComponent)
    this.grange.traverser.addView('manage-client', 'abb.orders.client', ManageClientComponent)
    this.grange.traverser.addView('manage-project', 'abb.orders.project', ManageProjectComponent)
    this.grange.traverser.addView('manage-pack', 'abb.orders.pack', ManagePackComponent)
    this.grange.traverser.addView('edit', 'abb.orders.project', EditAdminComponent)

    this.grange.traverser.addView('add', 'abb.orders.client', AddAdminComponent)

    this.grange.traverser.addView('templates', 'abb.orders.project', TemplateProjectComponent)
    this.grange.traverser.addView('view', 'abb.orders.project', WelcomeProjectComponent)
    this.grange.traverser.addView('add', 'abb.orders.project', AddComponent)

    this.grange.traverser.addView('view', 'folderish', FolderComponent)
    this.grange.traverser.addView('view', 'abb.orders.pack', ViewComponent)
    this.grange.traverser.addView('view', 'abb.orders.groupoptions', ViewComponent)
    this.grange.traverser.addView('view', 'abb.orders.maingroup', ViewComponent)
    this.grange.traverser.addView('view', 'abb.orders.order', ViewOrderComponent)
    this.grange.traverser.addView('edit', 'abb.orders.order', EditOrderComponent)
    this.grange.traverser.addView('view', 'abb.orders.changerequest', ViewChangeRequestComponent)
    this.grange.traverser.addView('view', 'abb.orders.pack', ViewPackComponent)
    this.grange.traverser.addView('addChangeRequest', '*', AddChangeRequestComponent)
    this.grange.traverser.addView('addChangeRequestStep2', '*', EditComponent)
    this.grange.traverser.addView('addOrder', '*', AddOrderComponent)
    this.grange.traverser.addView('addOrderStep2', '*', EditOrderComponent)
    this.grange.traverser.addView('view', 'Collection', FolderComponent)
    this.grange.traverser.addView('pre-order', '*', PreOrderComponent)
    this.grange.traverser.addView('orders-review', '*', OrdersReviewComponent)
    this.grange.traverser.addView('all-templates', '*', AllTemplatesComponent)
    this.grange.traverser.addView('all-change-requests', '*', AllChangeRequestsComponent)

    this.grange.traverser.addView('reportLBxlsx_form', '*', ReportLBxlsxFormComponent)
    this.grange.traverser.addView('reportTotal', '*', ReportTotalComponent)
    this.grange.traverser.addView('exportCRxlsx', '*', ExportCRxlsxComponent)
    this.grange.traverser.addView('logout', '*', LogoutComponent)
  }
}
