import { Component, OnDestroy } from '@angular/core'
import { MatFormFieldControl } from '@angular/material/form-field'
import { Subject } from 'rxjs'

@Component({
  selector: 'empty-input',
  template: `<input
    matInput
    ngModel="-"
    [readonly]="true"
    [ngModelOptions]="{ standalone: true }"
  />`,
  providers: [{ provide: MatFormFieldControl, useExisting: EmptyInputComponent }],
})
export class EmptyInputComponent implements MatFormFieldControl<String> {
  value = ''
  stateChanges = new Subject<void>()
  id = 'empty-' + Math.round(Math.random() * 10000).toString()
  placeholder = ''
  ngControl = null
  focused = false
  empty = true
  shouldLabelFloat = true
  required = false
  disabled = false
  errorState = false
  controlType = 'type-mat-input'
  setDescribedByIds = (ids) => {}
  onContainerClick = (event) => {}

  ngOnDestroy() {
    this.stateChanges.complete()
  }
}
