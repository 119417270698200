import { Directive, HostListener, Input, Output, EventEmitter } from '@angular/core'
import { Grange } from 'grange'

@Directive({
  selector: '[appDownloadFile]',
})
export class DownloadFileDirective {
  @Input() appDownloadFile: { url: string; title: string; emit: EventEmitter<boolean> }
  @HostListener('click') click() {
    this.downloadFile()
  }
  constructor(public grange: Grange) {}

  public downloadFile() {
    if (this.appDownloadFile.emit) {
      this.appDownloadFile.emit.emit(true)
    }
    this.grange.core.api.download(this.appDownloadFile.url).subscribe((result: Blob) => {
      const a = document.createElement('a')
      document.body.appendChild(a)
      const url = URL.createObjectURL(result)
      // window.open(url, '_blank');
      a.href = url
      a.download = this.appDownloadFile.title
      a.click()
      URL.revokeObjectURL(url)
      a.remove()
      if (this.appDownloadFile.emit) {
        this.appDownloadFile.emit.emit(false)
      }
    })
  }
}
