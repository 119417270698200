import { Pipe, PipeTransform } from '@angular/core'
import { Observable } from 'rxjs'
import { GrangeFile } from '@guillotinaweb/grange-core'
import { Grange } from 'grange'

@Pipe({
  name: 'grangeSrc',
})
export class GrangeSrcPipe implements PipeTransform {
  constructor(public grange: Grange) {}

  transform(file: GrangeFile): Observable<string> {
    return new Observable<string>((observer) => {
      if (file && file['content-type'] && file.data && file.encoding) {
        observer.next(`data:${file['content-type']};${file.encoding},${file.data}`)
        observer.complete()
      } else if (file && file.download) {
        this.grange.core.api.download(file.download).subscribe((result: Blob) => {
          const reader = new FileReader()
          reader.addEventListener(
            'load',
            () => {
              const src = typeof reader.result === 'string' ? reader.result : ''
              observer.next(src)
              observer.complete()
            },
            false
          )
          if (result) {
            reader.readAsDataURL(result)
          }
        })
      }
    })
  }
}
