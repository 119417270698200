import { Component, OnInit, Inject } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { Group } from '../interfaces'

export interface GroupDialogData {
  title: string
  group: Group
  mode: 'view' | 'edit' | 'add'
}

@Component({
  selector: 'app-groups-dialog',
  templateUrl: './groups-dialog.component.html',
  styleUrls: ['./groups-dialog.component.scss'],
})
export class GroupsDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<GroupsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: GroupDialogData
  ) {}

  ngOnInit() {}

  closeDialog() {
    this.dialogRef.close()
  }
}
