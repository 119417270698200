import { Component, OnInit } from '@angular/core'
import { GroupOption } from './group-option'
import { BaseFormsComponent } from '../base.forms.component'

@Component({
  selector: 'app-group-option',
  templateUrl: './group-option.component.html',
  styleUrls: ['./group-option.component.scss'],
})
export class GroupOptionComponent extends BaseFormsComponent<GroupOption> {}
