import { Pipe, PipeTransform } from '@angular/core'

interface Image {
  download: string
  height: number
  width: number
}

interface ItemImage {
  image?: {
    download: string
    height: number
    width: number
    filename: string
    'content-type': string
    size: number
    scales: {
      [scale: string]: Image
    }
  }
}

@Pipe({
  name: 'itemImage',
})
export class ItemImagePipe implements PipeTransform {
  transform(item: ItemImage, scale?: string): string {
    if (!!item.image && item.image.scales[scale]) {
      return item.image.scales[scale].download
    } else if (!!item.image && item.image.download) {
      return item.image.download
    }
    return null
  }
}
