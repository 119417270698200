import { GRANGE_TRANSLATIONS_EN } from 'grange'

export const en = {
  ...GRANGE_TRANSLATIONS_EN,
  sidebar: {
    'menu-button': 'Menu',
    contents: 'Contents',
    templates: 'Templates',
    users: 'Users',
  },
}
