import { Component } from '@angular/core'
import { BaseView, Grange } from 'grange'

@Component({
  selector: 'app-folder',
  templateUrl: './folder.component.html',
  styleUrls: ['./folder.component.scss'],
})
export class FolderComponent extends BaseView {
  public query = { path: { depth: 1 } }
  constructor(public grange: Grange) {
    super(grange)
  }
}
