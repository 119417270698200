import { Component, OnInit } from '@angular/core'
import { BaseView, Grange } from 'grange'

@Component({
  selector: 'app-all-change-requests',
  templateUrl: './all-change-requests.component.html',
  styleUrls: ['./all-change-requests.component.scss'],
})
export class AllChangeRequestsComponent extends BaseView implements OnInit {
  protected query = { portal_type: 'abb.orders.changerequest' }
  protected options = { metadata_fields: ['number', 'creator'] }
  constructor(public grange: Grange) {
    super(grange)
  }

  ngOnInit() {}
}
