import { Component, OnInit } from '@angular/core'
import { EditComponent } from '../edit/edit.component'

@Component({
  selector: 'app-edit-admin',
  templateUrl: './edit-admin.component.html',
  styleUrls: ['./edit-admin.component.scss'],
})
export class EditAdminComponent extends EditComponent {
  traverse(path: string) {
    this.grange.traverser.traverse(path)
  }
}
